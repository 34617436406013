import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import deleteBox from "../../../assets/images/trash.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { pairValidationSchema } from "../validation/pairsValidation";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import { getUnReservedDevice } from "../../DeviceManagement/middleware/device";
import {
  addPairDetail,
  deleteMultiplePair,
  deleteSinglePairDetail,
  getAllPairs,
  updatePair,
} from "../middleware/pairs";
import { getUnReservedChannel } from "../../Channel/middleware/channel";
import ToastService from "../../../helper/toast-services";
import { resetPairMessage } from "../slices/pairSlice";
// import refreshLogo from "../../../assets/images/refresh.svg";
import DeleteModal from "../../../common/DeleteModal";
import LocalstorageService from "../../../helper/localstorage-services";
import editPencil from "../../../assets/images/editPencil.svg";

interface Data {
  device: string;
  camera_feed: string;
  channel: string;
  status: string;
  action: HTMLElement;
}

interface ChannelSchema {
  channelName: string;
  channelReference: string;
  deviceName: string;
  camera: string;
  _id: string;
  userId: string;
  createdAt: string;
  status: string;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface DeleteSinglePairInterface {
  _id: string;
}

interface DeleteSingePairSchema {
  pair: string;
  channel: DeleteSinglePairInterface;
}
interface PairValueInterface {
  device: string;
  camera1: string;
  camera2: string;
  brightness1: number;
  brightness2: number;
  withBalance1: number;
  withBalance2: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "device",
    numeric: false,
    disablePadding: false,
    label: "Device",
  },
  {
    id: "camera_feed",
    numeric: false,
    disablePadding: false,
    label: "Camera feed",
  },
  {
    id: "channel",
    numeric: false,
    disablePadding: false,
    label: "Channel",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const Pairs = () => {
  const theme = useTheme();
  const [isOpenAdd, setIsOpenAdd] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const {
    pairDetail,
    pairDetailLoading,
    addPairMessage,
    addPairLoading,
    addPairError,
    deletePairLoading,
    deletePairMessage,
    deletePairError,
    deleteSinglePairLoading,
    deleteSinglePairMessage,
    deleteSinglePairError,
  } = useSelector((state: RootState) => state.pairs);
  const dispatch: AppDispatch = useDispatch();
  const { unReservedDevice, unReservedDeviceLoading } = useSelector(
    (state: RootState) => state.device
  );
  const { unReservedChannelLoading, unReservedChannel } = useSelector(
    (state: RootState) => state.channel
  );
  const [remainingChannel, setRemainingChannel] = useState<
    Array<ChannelSchema>
  >([]);
  const [selectedPair, setSelectedPair] = useState<Array<string>>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [singlePairData, setSinglePairData] = useState<any>(null);
  const [singleDeleteData, setSingleDeleteData] =
    useState<DeleteSingePairSchema | null>(null);
  const userData = LocalstorageService.getLoggedInUserDetails();
  useEffect(() => {
    if (!pairDetailLoading && !addPairLoading && initialLoading && pairDetail) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [pairDetail]);

  const handleCloseAddModal = () => {
    setIsOpenAdd(false);
    dispatch(resetPairMessage());
    setSinglePairData(null);
    formik.resetForm();
  };

  const handleSubmit = (values: PairValueInterface) => {
    if (singlePairData != null) {
      const payload = {
        device: values.device,
        camera1: values.camera1,
        camera2: values.camera2,
      };
      dispatch(updatePair(singlePairData?._id, payload));
    } else {
      const payload = {
        device: values.device,
        camera1: {
          channelId: values.camera1,
          bitrate: values.brightness1,
          whiteBalance: values.withBalance1,
        },
        camera2: {
          channelId: values.camera2,
          bitrate: values.brightness2,
          whiteBalance: values.withBalance2,
        },
      };
      dispatch(addPairDetail(payload));
    }
    // console.log("New");
  };

  const handleChange = (id: string) => {
    if (selectedPair.includes(id)) {
      const data = [...selectedPair];
      const dataIndex = data.findIndex((o) => {
        return o === id;
      });
      data.splice(dataIndex, 1);
      setSelectedPair(data);
    } else {
      setSelectedPair([...selectedPair, id]);
    }
  };

  const handleCloseDeleteModal = () => {
    dispatch(resetPairMessage());
    setSelectedPair([]);
    setSingleDeleteData(null);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    dispatch(getAllPairs(userData._id));
    // eslint-disable-next-line
  }, [addPairMessage, deletePairMessage, deleteSinglePairMessage]);

  useEffect(() => {
    if (addPairMessage && !addPairLoading) {
      ToastService.success(addPairMessage);
      handleCloseAddModal();
    }
    if (deleteSinglePairMessage && !deleteSinglePairLoading) {
      ToastService.success(deleteSinglePairMessage);
      handleCloseDeleteModal();
    }
    if (!deletePairLoading && deletePairMessage) {
      ToastService.success(deletePairMessage);
      handleCloseDeleteModal();
    }
    // eslint-disable-next-line
  }, [addPairMessage, deletePairMessage, deleteSinglePairMessage]);

  const handleOpenAddModal = () => {
    dispatch(getUnReservedDevice());
    dispatch(getUnReservedChannel());
    setIsOpenAdd(true);
    setRemainingChannel([]);
  };

  const initialValues: PairValueInterface = {
    device: "",
    camera1: "",
    camera2: "",
    brightness1: 2000,
    brightness2: 2000,
    withBalance1: 6500,
    withBalance2: 6500,
  };

  const handleSingleDelete = (
    pair: string,
    camera: DeleteSinglePairInterface | null
  ) => {
    if (camera !== null) {
      setOpenDeleteDialog(true);
      setSingleDeleteData({ pair: pair, channel: camera });
    }
  };

  const handleSinglePairDetailDelete = () => {
    if (singleDeleteData !== null) {
      dispatch(
        deleteSinglePairDetail(singleDeleteData.pair, {
          id: singleDeleteData.channel._id,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: pairValidationSchema,
  });

  useEffect(() => {
    if (
      unReservedChannel &&
      unReservedChannel.length > 0 &&
      formik.values.camera1
    ) {
      setRemainingChannel(
        unReservedChannel.filter((o: ChannelSchema) => {
          return o._id !== formik.values.camera1;
        })
      );
    }
  }, [formik.values.camera1]);

  useEffect(() => {
    if (singlePairData) {
      formik.setFieldValue("device", singlePairData?.device?._id);
      formik.setFieldValue("camera1", singlePairData?.camera1?._id);
      formik.setFieldValue("camera2", singlePairData?.camera2?._id);
    }
    // eslint-disable-next-line
  }, [singlePairData]);

  const handleDelete = () => {
    dispatch(deleteMultiplePair({ id: selectedPair }));
  };
  // console.log(pairDetail);

  const handlePairEdit = (id: string) => {
    const singlePair = pairDetail?.find((pair) => pair._id === id);
    setSinglePairData(singlePair);
    dispatch(getUnReservedDevice());
    dispatch(getUnReservedChannel());
    setIsOpenAdd(true);
  };

  return (
    <>
      <div className="mainPageHeading">
        <h1
          className={`${
            theme.palette.mode === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Pairs
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="primary"
            variant="contained"
            type="button"
            size="large"
            style={{ width: "260px" }}
            onClick={handleOpenAddModal}
            className="loginButton"
          >
            <AddIcon />
            Add Pairs
          </Button>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            sx={{
              borderColor: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              color: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              "&:hover": {
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
              },
            }}
            size="large"
            onClick={() => setOpenDeleteDialog(true)}
            // disabled={deleteDeviceLoading}
            className="loginButton"
          >
            Delete
            {/* {deleteDeviceLoading ? <CircularProgress color="inherit" /> : "Delete"} */}
          </Button>
        </div>
      </div>

      {pairDetailLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        <span
                          className={`${
                            theme.palette.mode === "light"
                              ? "login-text-dark"
                              : ""
                          } tableHead`}
                        >
                          {headCell.label}
                        </span>
                      </TableCell>
                    ))}
                    <TableCell>
                      {" "}
                      <span
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        EDIT
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pairDetail &&
                    pairDetail.length > 0 &&
                    pairDetail.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              onChange={() => handleChange(row._id)}
                              checked={
                                selectedPair.includes(row._id) ? true : false
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {row.device?.deviceName
                                ? row.device?.deviceName
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.camera1 !== null && (
                              <p
                                className={`${
                                  theme.palette.mode === "light"
                                    ? "login-text-dark"
                                    : ""
                                } tableRows`}
                              >
                                Cam 1
                              </p>
                            )}
                            {row.camera2 !== null && (
                              <p
                                className={`${
                                  theme.palette.mode === "light"
                                    ? "login-text-dark"
                                    : ""
                                } tableRows`}
                              >
                                Cam 2
                              </p>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.camera1 !== null && (
                              <p
                                className={`${
                                  theme.palette.mode === "light"
                                    ? "login-text-dark"
                                    : ""
                                } tableRows`}
                              >
                                {row?.camera1?.channelReference}
                              </p>
                            )}

                            {row.camera2 !== null && (
                              <p
                                className={`${
                                  theme.palette.mode === "light"
                                    ? "login-text-dark"
                                    : ""
                                } tableRows`}
                              >
                                {row?.camera2?.channelReference}
                              </p>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.camera1 !== null &&
                              (row.camera1.status === "active" ? (
                                <p style={{ color: "#28C76F" }}>Active</p>
                              ) : (
                                <p style={{ color: "#EA5455" }}>Inactive</p>
                              ))}

                            {row.camera2 !== null &&
                              (row.camera2.status === "active" ? (
                                <p style={{ color: "#28C76F" }}>Active</p>
                              ) : (
                                <p style={{ color: "#EA5455" }}>Inactive</p>
                              ))}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.camera1 !== null && (
                              <p className="refreshImg">
                                {/* <img src={refreshLogo} alt="refresh" /> */}
                                <img
                                  onClick={() =>
                                    handleSingleDelete(row._id, row.camera1)
                                  }
                                  src={deleteBox}
                                  alt="delete"
                                />
                              </p>
                            )}

                            {row.camera2 !== null && (
                              <p className="refreshImg">
                                {/* <img src={refreshLogo} alt="refresh" /> */}
                                <img
                                  onClick={() =>
                                    handleSingleDelete(row._id, row.camera2)
                                  }
                                  src={deleteBox}
                                  alt="delete"
                                />
                              </p>
                            )}
                          </TableCell>
                          <TableCell>
                            <img
                              onClick={() => handlePairEdit(row._id)}
                              src={editPencil}
                              alt="edit"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {pairDetail && pairDetail.length === 0 && (
              <span
                className={`${
                  theme.palette.mode === "light" ? "login-text-dark" : ""
                } no-data-found tableRows`}
              >
                No Pairs Found
              </span>
            )}
          </Paper>
        </Box>
      )}

      <Dialog
        fullWidth
        open={isOpenAdd}
        onClose={handleCloseAddModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Pairs</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseAddModal}
            />
          </div>
        </DialogTitle>
        {unReservedDeviceLoading && unReservedChannelLoading ? (
          <div className="spinnerDiv">
            <CircularProgress />
          </div>
        ) : (
          <>
            {(unReservedDevice && unReservedDevice.length === 0) ||
            (unReservedChannel && unReservedChannel.length === 0) ? (
              <DialogContent>
                <Alert severity="warning">
                  {`You are out of ${
                    unReservedDevice && unReservedDevice.length === 0
                      ? "devices"
                      : "channels"
                  } please add new ${
                    unReservedDevice && unReservedDevice.length === 0
                      ? "devices"
                      : "channels"
                  } and try again!`}
                </Alert>
              </DialogContent>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                  {addPairError && (
                    <Alert severity="error">{addPairError}</Alert>
                  )}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "20px" }}
                    error={
                      formik.touched.device && Boolean(formik.errors.device)
                    }
                  >
                    <InputLabel id="demo-simple-select-error-label">
                      Device
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="device"
                      value={formik.values.device}
                      label="Device"
                      name="device"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {unReservedDevice.map((o) => (
                        <MenuItem key={o._id} value={o._id}>
                          {o?.deviceName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.device && formik.errors.device}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "20px" }}
                    error={
                      formik.touched.camera1 && Boolean(formik.errors.camera1)
                    }
                  >
                    <InputLabel id="demo-simple-select-error-label">
                      Camera 1
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="camera1"
                      value={formik.values.camera1}
                      label="Camera 1"
                      name="camera1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {unReservedChannel.map((o) => (
                        <MenuItem key={o._id} value={o._id}>
                          {o.channelReference}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.camera1 && formik.errors.camera1}
                    </FormHelperText>
                  </FormControl>
                  {singlePairData && singlePairData ? (
                    ""
                  ) : (
                    <Grid style={{ marginTop: "20px" }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-error-label">
                          Bitrate
                        </InputLabel>
                        <Slider
                          aria-label="Temperature"
                          name="brightness1"
                          defaultValue={2000}
                          value={formik.values.brightness1}
                          onChange={formik.handleChange}
                          valueLabelDisplay="on"
                          step={100}
                          min={1000}
                          max={4000}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-error-label">
                          White Balance
                        </InputLabel>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={2000}
                          name="withBalance1"
                          value={formik.values.withBalance1}
                          onChange={formik.handleChange}
                          valueLabelDisplay="on"
                          step={100}
                          min={1000}
                          max={4000}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <FormControl
                    fullWidth
                    style={{ marginTop: "20px" }}
                    error={
                      formik.touched.camera2 && Boolean(formik.errors.camera2)
                    }
                  >
                    <InputLabel id="demo-simple-select-error-label">
                      Camera 2
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="demo-simple-select-error"
                      name="camera2"
                      value={formik.values.camera2}
                      label="Camera 2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {remainingChannel &&
                        remainingChannel.length > 0 &&
                        remainingChannel.map((o: ChannelSchema) => (
                          <MenuItem key={o._id} value={o._id}>
                            {o.channelReference}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.camera2 && formik.errors.camera2}
                    </FormHelperText>
                  </FormControl>
                  {singlePairData && singlePairData ? (
                    ""
                  ) : (
                    <Grid style={{ marginTop: "20px" }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-error-label">
                          Bitrate
                        </InputLabel>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={2000}
                          name="brightness2"
                          value={formik.values.brightness2}
                          onChange={formik.handleChange}
                          valueLabelDisplay="on"
                          step={100}
                          min={1000}
                          max={4000}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-error-label">
                          White Balance
                        </InputLabel>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={2000}
                          name="withBalance2"
                          value={formik.values.withBalance2}
                          onChange={formik.handleChange}
                          valueLabelDisplay="on"
                          step={100}
                          min={1000}
                          max={4000}
                        />
                      </Grid>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions
                  style={{ marginBottom: "20px", justifyContent: "center" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                      borderColor: `${
                        theme.palette.mode === "dark"
                          ? "primary.light"
                          : "primary.main"
                      }`,
                      color: `${
                        theme.palette.mode === "dark"
                          ? "primary.light"
                          : "primary.main"
                      }`,
                      "&:hover": {
                        borderColor: `${
                          theme.palette.mode === "dark"
                            ? "primary.dark"
                            : "primary.main"
                        }`,
                        color: `${
                          theme.palette.mode === "dark"
                            ? "primary.dark"
                            : "primary.main"
                        }`,
                      },
                    }}
                    type="button"
                    size="large"
                    style={{ width: "270px" }}
                    className="loginButton"
                    disabled={addPairLoading}
                    onClick={handleCloseAddModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: "270px" }}
                    type="submit"
                    size="large"
                    disabled={addPairLoading}
                    // onClick={handleSubmit}
                    className="loginButton"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          </>
        )}
      </Dialog>

      <DeleteModal
        isOpen={openDeleteDialog}
        handleClose={handleCloseDeleteModal}
        deleteErrorMessage={deletePairError}
        loading={deletePairLoading}
        handleOnDelete={handleDelete}
        title="Once it is deleted you will not be able to recover the data again!"
        buttonName="Delete"
      />

      <DeleteModal
        isOpen={openDeleteDialog}
        handleClose={handleCloseDeleteModal}
        deleteErrorMessage={deleteSinglePairError}
        loading={deleteSinglePairLoading}
        handleOnDelete={handleSinglePairDetailDelete}
        title="Once it is deleted you will not be able to recover the data again!"
        buttonName="Delete"
      />
    </>
  );
};

export default Pairs;
