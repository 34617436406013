import React, { useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Alert,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { signUpValidation } from "../validation/authValidationSchema";
import { useFormik } from "formik";
import ROUTE_URLS from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../middleware/auth";
import footer from "../../../assets/images/loginFooter.svg";
import { AppDispatch, RootState } from "../../../config/store";
import ToastService from "../../../helper/toast-services";
import { resetSignUpMessage } from "../slices/authSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SignupInterface } from "../../../common/interfaces/interface";

export default function SignUp() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { signUpLoading, signUpError, signUpMessage } = useSelector(
    (state: RootState) => state.auth
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const initialValues: SignupInterface = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  useEffect(() => {
    if (signUpMessage && !signUpError) {
      ToastService.success(signUpMessage);
      navigate(ROUTE_URLS.LOGIN);
      formik.resetForm();
      dispatch(resetSignUpMessage());
    }
  }, [signUpMessage]);

  const handleSubmit = (values: SignupInterface) => {
    dispatch(signUpUser(values));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: signUpValidation,
  });

  return (
    <div className="center-auth-component">
      <CssBaseline />
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 350,
          }}
        >
          <h1 className="login-text">Sign Up</h1>
          {signUpError && <Alert severity="error">{signUpError}</Alert>}
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error"
                name="firstName"
                label="First name"
                placeholder="Enter firstname"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                autoComplete="off"
                size="small"
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error"
                name="lastName"
                label="Last Name"
                placeholder="Enter lastname"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                autoComplete="off"
                size="small"
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error"
                name="email"
                label="Email"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="off"
                size="small"
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="standard-password-input"
                name="password"
                label="Password"
                placeholder="Enter password"
                type={`${showPassword ? "text" : "password"}`}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                autoComplete="off"
                size="small"
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                style={{ marginTop: "20px" }}
                type="submit"
                className="loginButton"
                size="large"
              >
                {signUpLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Sign UP"
                )}
              </Button>
              <p
                className={`${
                  theme.palette.mode === "dark"
                    ? "register-link-light"
                    : "register-link-dark"
                } register-link`}
              >
                Already have an account!{" "}
                <Link className="login-signup-link" to={ROUTE_URLS.LOGIN}>
                  Login
                </Link>
              </p>
            </div>
          </form>
        </Box>
      </div>
      <img className="footer-wave" src={footer} alt="footer" />
    </div>
  );
}
