import {
  LOCALSTORAGE_ADMIN_AUTH_DETAILS,
  LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
  LOCALSTORAGE_USER_AUTH_TOKEN_KEY,
  THEME,
} from "../config/constants";

function getLoggedInUserToken() {
  const token = localStorage.getItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  return token || null;
}

function setThemeMode(theme: string) {
  const setTheme = localStorage.setItem(THEME, theme);
  return setTheme;
}

function setLoggedInUserDetails(user: object) {
  localStorage.setItem(
    LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
    JSON.stringify(user)
  );
}

function getThemeMode() {
  const getTheme = localStorage.getItem(THEME);
  return getTheme;
}

export interface UserDetailsInterface {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: string;
  createdAt: string;
}

function getLoggedInUserDetails() {
  const userDetails = localStorage.getItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY);
  return userDetails ? JSON.parse(userDetails) : null;
}
function getLoggedInAdminDetails() {
  const adminDetails = localStorage.getItem(LOCALSTORAGE_ADMIN_AUTH_DETAILS);
  return adminDetails ? JSON.parse(adminDetails) : null;
}

function logoutUser() {
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY);
  localStorage.removeItem(LOCALSTORAGE_ADMIN_AUTH_DETAILS);
}

function loginUser(token: string, userObj: object) {
  localStorage.setItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY, token);
  localStorage.setItem(
    LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
    JSON.stringify(userObj)
  );
  const typedUserObj = userObj as { role: string, _id: string };
  const data = { role: typedUserObj.role, _id: typedUserObj._id }
  localStorage.setItem(
    LOCALSTORAGE_ADMIN_AUTH_DETAILS,
    JSON.stringify(data)
  );
}

const LocalstorageService = {
  getLoggedInUserToken,
  logoutUser,
  loginUser,
  setLoggedInUserDetails,
  getLoggedInUserDetails,
  getLoggedInAdminDetails,
  setThemeMode,
  getThemeMode,
};

export default LocalstorageService;
