import { get, post } from "../../../helper";

const URL = "user";

const getAllUserWithPagination = (payload: object) => {
	return post(`${URL}/all`, payload);
};

const getOneUserDetail = (user_id: string) => {
	return get(`${URL}/${user_id}`);
};

export const UserServices = {
	getAllUserWithPagination,
	getOneUserDetail,
};
