/* eslint-disable no-mixed-spaces-and-tabs */
import * as yup from "yup";
import {
	ForgotPasswordInterface,
	LoginInterface,
	PasswordResetInterface,
	SignupInterface,
} from "../../../common/interfaces/interface";

export const authValidation: yup.ObjectSchema<LoginInterface> = yup.object(
	{
		email: yup
			.string()
			.required("Email is required")
			.email("Enter a valid email"),
		password: yup.string().required("Password is required"),
	}
);

export const signUpValidation: yup.ObjectSchema<SignupInterface> = yup.object({
	firstName: yup.string().required("First name is required"),
	lastName: yup.string().required("Last name is required"),
	email: yup
		.string()
		.required("Email is required")
		.email("Enter a valid email"),
	password: yup
		.string()
		.required("Password is required")
		.min(6, "Password should be of minimum 6 characters length"),
});

export const forgotPasswordValidation: yup.ObjectSchema<ForgotPasswordInterface> =
  yup.object({
  	email: yup
  		.string()
  		.required("Email is required")
  		.email("Enter a valid email"),
  });

export const resetPasswordValidation: yup.ObjectSchema<PasswordResetInterface> =
  yup.object({
  	new_password: yup
  		.string()
  		.required("Password is required")
  		.min(6, "Password should be of minimum 6 characters length"),

  	// .matches(
  	//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  	//   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  	// ),
  	confirm_password: yup
  		.string()
  		.required("Confirm Password is required")
  		.oneOf(
  			[yup.ref("new_password")],
  			"Password and confirm passwords do not match"
  		),
  });
