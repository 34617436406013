import {
  InputAdornment,
  IconButton,
  Button,
  CssBaseline,
  TextField,
  Box,
  Container,
  Alert,
  Stack,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../middleware/profile";
import { updatePasswordValidation } from "../validation/profileValidationSchema";
import { AppDispatch, RootState } from "../../../config/store";

interface ResetPasswordInterface {
  old_password: string;
  new_password: string;
}

export default function UpdatePassword() {
  const dispatch: AppDispatch = useDispatch();
  const { changePasswordLoading, changePasswordError } = useSelector(
    (state: RootState) => state.profile
  );

  const initialValues = {
    old_password: "",
    new_password: "",
  };

  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
  });

  const handlePasswordShow = () => {
    setShowPassword({
      password: !showPassword.password,
      newPassword: showPassword.newPassword,
    });
  };
  const handleNewPasswordShow = () => {
    setShowPassword({
      password: showPassword.password,
      newPassword: !showPassword.newPassword,
    });
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleSubmit = (values: ResetPasswordInterface) => {
    // API dispatch calling here... //
    const payload = {
      old_password: values.old_password,
      new_password: values.new_password,
    };
    dispatch(updatePassword(payload));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: updatePasswordValidation,
  });

  return (
    <div className="">
      <Container component="main" maxWidth="xs" className="login-box">
        <CssBaseline />
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{ width: "100%" }}
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              {changePasswordError && (
                <Alert severity="error">{changePasswordError}</Alert>
              )}
            </Stack>

            <form onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="standard-password-input"
                  name="old_password"
                  label="Current Password"
                  placeholder="********"
                  type={showPassword.password ? "text" : "password"}
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.old_password &&
                    Boolean(formik.errors.old_password)
                  }
                  helperText={
                    formik.touched.old_password && formik.errors.old_password
                  }
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordShow}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),

                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginTop: "20px" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  id="standard-password-input"
                  name="new_password"
                  label="New Password"
                  placeholder="********"
                  type={showPassword.newPassword ? "text" : "password"}
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.new_password &&
                    Boolean(formik.errors.new_password)
                  }
                  helperText={
                    formik.touched.new_password && formik.errors.new_password
                  }
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleNewPasswordShow}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.newPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  className="loginButton"
                  style={{ marginBottom: "20px" }}
                >
                  {changePasswordLoading ? (
                    <CircularProgress color="inherit" size="small" />
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </Container>
    </div>
  );
}
