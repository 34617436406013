import { createSlice } from "@reduxjs/toolkit";
import { ChannelSchema } from "../../Channel/slices/channelSlice";

interface SingleUserDetailInterface {
  broadcasts: any;
  firstName: any;
  lastName: any;
  devices: any;
  channels: any;
  pairs: PairSchemaInterface;
  _id: string;
  device: DeviceSchema;
  camera1: string;
  camera2: string;
  created_by: string;
}

interface PairSchema {
  device: DeviceSchema;
  _id: string;
  camera1: ChannelSchema;
  camera2: ChannelSchema;
  createdAt: string;
}

type PairSchemaInterface = PairSchema[];

interface UserSchema {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  createdAt: string;
  devices: DevicesInterface;
}

interface CameraSchema {
  camera1: string;
  camera2: string;
}

interface DeviceSchema {
  deviceCode: string;
  deviceName: string;
  is_reserve: boolean;
  serial: string;
  _id: string;
  userId: string;
  createdAt: string;
  status: string;
  camera: CameraSchema;
}

type DevicesInterface = DeviceSchema[];

interface MetaDataInterface {
  page: number;
  count: number;
  limit: number;
}

interface UserDetailSchema {
  data: userDetailsSchema;
  metadata: MetaDataInterface;
}

type userDetailsSchema = UserSchema[];

interface UserInterface {
  userLoading: boolean;
  userDetails: UserDetailSchema | null;
  userDetailsError: string;

  oneUserDetailLoading: boolean;
  oneUserDetail: SingleUserDetailInterface | null;
  oneUserDetailError: string;
}

const initialState: UserInterface = {
  userLoading: true,
  userDetails: null,
  userDetailsError: "",

  oneUserDetailLoading: false,
  oneUserDetail: null,
  oneUserDetailError: "",
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    userDetailsRequest: (state) => {
      state.userLoading = true;
      state.userDetailsError = "";
    },
    userDetailsSuccess: (state, action) => {
      state.userLoading = false;
      state.userDetails = action.payload;
    },
    userDetailsError: (state, action) => {
      state.userLoading = false;
      state.userDetailsError = action.payload;
    },

    getOneUserDetailRequest: (state) => {
      state.oneUserDetailLoading = true;
      state.oneUserDetailError = "";
    },
    getOneUserDetailSuccess: (state, action) => {
      state.oneUserDetailLoading = false;
      state.oneUserDetail = action.payload;
    },
    getOneUserDetailError: (state, action) => {
      state.oneUserDetailLoading = false;
      state.oneUserDetailError = action.payload;
    },

    resetUserDetails: (state) => {
      state.oneUserDetailError = "";
      state.oneUserDetail = null;
    },
  },
});

export const {
  userDetailsRequest,
  userDetailsSuccess,
  userDetailsError,

  getOneUserDetailRequest,
  getOneUserDetailSuccess,
  getOneUserDetailError,

  resetUserDetails,
} = userSlice.actions;

export default userSlice.reducer;
