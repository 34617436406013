import React from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import LocalstorageService from "../../helper/localstorage-services";

const Dashboard = () => {
	const nav = useNavigate();

	const userRole = LocalstorageService.getLoggedInUserDetails();

	useEffect(() => {
		if (userRole?.role === "admin") {
			nav(ROUTE_URLS.USERS);
		} else {
			nav(ROUTE_URLS.DEVICE_MANAGEMENT);
		}
	}, [userRole]);

	return (
		<Box sx={{ flexGrow: 1 }}>
			{/* <Typography gutterBottom variant="h5" component="div">
        Dashboard
      </Typography> */}
			{/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {dashboard.map((list, index) => (
          <Grid
            key={index}
            item
            xs={2}
            sm={4}
            md={4}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <div className="card-hover-animation">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia sx={{ height: 180 }} image={list.images} title={list.title} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {list.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {list.description}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid> */}
		</Box>
	);
};

export default Dashboard;
