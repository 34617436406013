/* eslint-disable no-mixed-spaces-and-tabs */
import * as yup from "yup";

interface UpdatePasswordValidationSchema {
  old_password: string;
  new_password: string;
}

interface ProfileUpdateValidationSchema {
  first_name: string;
  last_name: string;
  phone_number: string;
}

export const updatePasswordValidation: yup.ObjectSchema<UpdatePasswordValidationSchema> =
  yup.object({
  	old_password: yup.string().required("Password is required"),
  	new_password: yup
  		.string()
  		.required("Confirm Password is required")
  		.oneOf(
  			[yup.ref("new_password")],
  			"Password and confirm passwords do not match"
  		),
  });

export const profileUpdateValidation: yup.ObjectSchema<ProfileUpdateValidationSchema> =
  yup.object({
  	first_name: yup.string().required("Firstname is required"),
  	last_name: yup.string().required("Lastname is required"),
  	phone_number: yup.string().required("Phone Number is required"),
  });
