import * as yup from "yup";

// interface BroadcastValidationInterface {
//   title: string;
//   pairs?: string[] | null;
// }

export const broadcastValidation = yup.object({
	title: yup.string().required("Title is required"),
	pairs: yup
		.array()
		.of(yup.string())
		.min(1, "You can't device this blank.")
		.required("You can't device this blank.")
		.nullable(),
});
