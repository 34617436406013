import { post } from "../../../helper";

const URI = "/user";

const login = (payload: object) => {
	return post("auth/login", payload);
};

const signup = (payload: object) => {
	return post(`${URI}/signup`, payload);
};

const forgotPassword = (payload: object) => {
	return post("auth/forgotPassword", payload);
};

const resetPassword = (payload: object) => {
	return post("auth/resetPassword", payload);
};

const AuthService = {
	login,
	signup,
	forgotPassword,
	resetPassword,
};
export default AuthService;
