export function dateWithMonthFormat(dateString: string) {
	try {
		const dateObj = new Date(dateString);
		const day = dateObj.getDate();
		const month = dateObj.toLocaleString("default", { month: "short" });
		const year = dateObj.getFullYear();
		return `${day > 9 ? day : 0 + day} ${month} ${year} ${" "}
        `;
	} catch (error) {
		return "-----";
	}
}

export function timeOnlyFormat(dateString: string) {
	try {
		const dateObj = new Date(dateString);
		const hour = dateObj.getHours();
		const minute = dateObj.getMinutes();
		return `${hour > 12 ? "0" + (hour - 12) : hour}:${
			minute > 9 ? minute : "0" + minute
		} ${hour > 12 ? "PM" : "AM"}`;
	} catch (error) {
		return "-----";
	}
}
