import React from "react";

const Analytics = () => {
	return (
		<div className='mainPageHeading'>
			<h1 className='pageHeading'>Analytics</h1>
		</div>
	);
};

export default Analytics;
