import * as yup from "yup";

// interface DeviceValidationInterface {
//   deviceName: string;
//   serial: string;
//   deviceType: string;
//   wifi_settings: WifiSettingInterface | null;
// }

export const deviceValidation = yup.object({
  // export const deviceValidation: yup.ObjectSchema<any> = yup.object({
  deviceName: yup.string().required("Device name is required"),
  deviceType: yup.string().required("Please select any device type"),
  serial: yup.string().required("Serial code is required"),
  wifi_settings: yup
    .array()
    .of(
      yup.object().shape({
        ssid: yup.string().required("Wifi SSID is required"),
        password: yup.string().required("Wifi password is required"),
      })
    )
    .nullable(),
});
