import { delPayload, get, post, put } from "../../../helper";

const URI = "channel";

const getChannelListByID = (user_id: string) => {
  return get(`${URI}/list?userId=${user_id}`);
};

const getChannelList = () => {
  return get(`${URI}/list`);
};

const getCameraList = () => {
  return get(`${URI}/camera`);
};

const addChannelDetail = (payload: object) => {
  return post(`${URI}/create`, payload);
};

const updateChannelDetail = (channel_id: string, payload: object) => {
  return put(`${URI}/update/${channel_id}`, payload);
};

const deleteChannelDetail = (payload: object) => {
  return delPayload(`${URI}/delete`, payload);
};

const updateChannelRangeData = (channel_id: string, payload: object) => {
  return put(`${URI}/range/${channel_id}`, payload);
};

const getUnReservedChannel = () => {
  return get(`${URI}/reserve`);
};

const ChannelService = {
  getChannelListByID,
  getChannelList,
  addChannelDetail,
  getCameraList,
  deleteChannelDetail,
  updateChannelRangeData,
  getUnReservedChannel,
  updateChannelDetail,
};
export default ChannelService;
