import { AppDispatch } from "../../../config/store";
import LocalstorageService from "../../../helper/localstorage-services";
import AuthService from "../services/auth";
import {
	forgotPasswordError,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	loginError,
	loginRequest,
	loginSuccess,
	resetPasswordError,
	resetPasswordRequest,
	resetPasswordSuccess,
	signUpError,
	signUpRequest,
	signUpSuccess,
} from "../slices/authSlice";

export const loginUser = (payload: object) => {
	return (dispatch: AppDispatch) => {
		dispatch(loginRequest());
		AuthService.login(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					LocalstorageService.loginUser(data.token, data.user);
					dispatch(loginSuccess({ ...data, message }));
				} else {
					dispatch(loginError(error));
				}
			})
			.catch((error) => {
				dispatch(loginError(error));
			});
	};
};

export function signUpUser(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(signUpRequest());
		AuthService.signup(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(signUpSuccess({ ...data, message }));
				} else {
					dispatch(signUpError(error));
				}
			})
			.catch((error) => {
				dispatch(signUpError(error));
			});
	};
}
export function forgotPassword(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(forgotPasswordRequest());
		AuthService.forgotPassword(payload)
			.then((response) => {
				const { success, message, error } = response.data;
				if (success) {
					dispatch(forgotPasswordSuccess(message));
				} else {
					dispatch(forgotPasswordError(error));
				}
			})
			.catch((error) => {
				dispatch(forgotPasswordError(error));
			});
	};
}

export function resetPassword(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(resetPasswordRequest());
		AuthService.resetPassword(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(resetPasswordSuccess({ ...data, message }));
				} else {
					dispatch(resetPasswordError(error));
				}
			})
			.catch((error) => {
				dispatch(resetPasswordError(error));
			});
	};
}
