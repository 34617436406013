import React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Container,
  Alert,
  Stack,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserDetails, updateProfile } from "../middleware/profile";
import { useEffect, useState } from "react";
import { profileUpdateValidation } from "../validation/profileValidationSchema";
import UpdateProfileImage from "./updateProfileImage";
import { AppDispatch, RootState } from "../../../config/store";

export default function UpdateProfile() {
  const dispatch: AppDispatch = useDispatch();
  const { changeProfileLoading, getUserLoading, getUserDetails, getUserError } =
    useSelector((state: RootState) => state.profile);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    dispatch(getLoggedUserDetails());
  }, []);

  useEffect(() => {
    if (getUserDetails !== null) {
      setInitialValues({
        firstName: getUserDetails !== null ? getUserDetails?.firstName : "",
        lastName: getUserDetails !== null ? getUserDetails?.lastName : "",
        email: getUserDetails !== null ? getUserDetails?.email : "",
      });
    }
  }, [getUserDetails]);

  const handleSubmit = () => {
    // API dispatch calling here... //
    dispatch(updateProfile(formik.values));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: profileUpdateValidation,
  });

  return (
    <Grid sx={{ marginTop: "20px" }} container spacing={4}>
      <Grid item xs>
        <UpdateProfileImage user={getUserDetails} />
      </Grid>
      <Grid item xs>
        <Card sx={{ minHeight: "355px" }}>
          <CardContent>
            {getUserLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <CssBaseline />
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{ width: "100%" }}
                      spacing={2}
                      style={{ marginTop: "10px" }}
                    >
                      {getUserError && (
                        <Alert severity="error">{getUserError}</Alert>
                      )}
                    </Stack>

                    <TextField
                      fullWidth
                      variant="outlined"
                      id="outlined-error"
                      name="firstName"
                      label="First Name"
                      placeholder="Enter First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      autoComplete="off"
                      style={{ marginTop: "20px" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="outlined-error"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      autoComplete="off"
                      style={{ marginTop: "20px" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="outlined-error"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      autoComplete="off"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="button"
                      className="loginButton"
                      onClick={handleSubmit}
                      style={{ marginBottom: "20px" }}
                    >
                      {changeProfileLoading ? <CircularProgress /> : "Submit"}
                    </Button>
                  </Box>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
