import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SidebarMenu, settings } from "./data";
import {
  Avatar,
  Button,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import { ThemeProvider, alpha, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../profile/middleware/profile";
import { AppDispatch, RootState } from "../../config/store";
import ToastService from "../../helper/toast-services";
import { resetLogoutUser } from "../profile/slices/profileSlice";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import sidebarLogo from "../../assets/images/sidebar/sideBarLogo.svg";
import LocalstorageService from "../../helper/localstorage-services";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  setThemeMode: (value: boolean) => void;
  themeMode: boolean;
}

export default function Layout(props: Props) {
  const theme = useTheme();
  const { window, setThemeMode, themeMode } = props;
  const dispatch: AppDispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  React.useEffect(() => {
    const themeData = LocalstorageService.getThemeMode();
    if (themeData && themeData !== null) {
      setThemeMode(themeData === "dark" ? true : false);
    }
  });

  const { logoutUserDetails, logoutUserError } = useSelector(
    (state: RootState) => state.profile
  );
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState<string>("");

  const userRole = LocalstorageService.getLoggedInUserDetails();
  const adminRole = LocalstorageService.getLoggedInAdminDetails();

  // view as admin function
  const changleRole = (user_id: string) => {
    const userData = { ...userRole, role: "admin", _id: user_id };
    LocalstorageService.setLoggedInUserDetails(userData);
    navigate(ROUTE_URLS.USERS);
  };

  React.useEffect(() => {
    setActiveTab(location.pathname);
  });

  React.useEffect(() => {
    if (logoutUserDetails && !logoutUserError) {
      ToastService.success(logoutUserDetails);
      dispatch(resetLogoutUser());
      navigate(ROUTE_URLS.LOGIN);
    } else if (!logoutUserDetails && logoutUserError) {
      ToastService.error(logoutUserError);
      dispatch(resetLogoutUser());
    }
    // eslint-disable-next-line
  }, [logoutUserDetails, logoutUserError]);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List className="sidebarNav">
        {SidebarMenu.map((list, index) => (
          <React.Fragment key={index}>
            {userRole?.role === list.role && (
              <Link
                to={list.link}
                className={`${
                  theme.palette.mode === "dark"
                    ? "link-format-white"
                    : "link-format-black"
                } link-format`}
                onClick={() => setActiveTab(list.link)}
              >
                <div
                  className={`${activeTab.includes(list.link) && "activeTab"}`}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {activeTab.includes(list.link) ? (
                          <img src={list.iconWhite} alt={list.iconWhite} />
                        ) : (
                          <img src={list.iconGrey} alt={list.iconGrey} />
                        )}
                        {/* {<list.icon className={`${activeTab == list.link && "activeTab"}`} />} */}
                      </ListItemIcon>
                      <ListItemText primary={list.title} />
                    </ListItemButton>
                  </ListItem>
                </div>
              </Link>
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleThemeModeChange = (
    e: React.ChangeEvent<HTMLInputElement | any>
  ) => {
    setThemeMode(e.target.checked);
    LocalstorageService.setThemeMode(
      e.target.checked === true ? "dark" : "light"
    );
  };

  const MaterialUISwitch = styled(Switch)(({ theme }: any) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
          // background:
          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--light-solid-color-primary-500-base, #6D3190)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      // background:
      //   theme.palette.mode === "dark"
      //     ? "#6D3190"
      //     : "linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), var(--light-solid-color-primary-500-base, #6D3190)",
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      // background:
      //   "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--light-solid-color-primary-500-base, #6D3190)",
      borderRadius: 20 / 2,
    },
  }));

  const Search = styled("div")(({ theme }: any) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }: any) => ({
    color: theme.palette.mode === "light" ? "#000" : "#fff",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }: any) => ({
    color: theme.palette.mode === "light" ? "#000" : "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const userData = LocalstorageService.getLoggedInUserDetails();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppBar
          className="header"
          position="fixed"
          color="primary"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="logo">
                <Typography variant="h6" noWrap component="div">
                  <Link
                    to={ROUTE_URLS.DASHBOARD}
                    className={`${
                      theme.palette.mode === "dark"
                        ? "link-format-white"
                        : "link-format-black"
                    } link-format`}
                  >
                    <img src={sidebarLogo} alt="" />
                    Bond Cam
                  </Link>
                </Typography>
              </Grid>
              <div
                className={`${
                  theme.palette.mode === "dark" ? "darkBackground" : ""
                } contentHead`}
              >
                <Search className="headSearch">
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
                <Grid className="headUser">
                  <Grid>
                    <FormGroup>
                      <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 1 }} />}
                        checked={themeMode === true ? true : false}
                        value={themeMode}
                        onChange={handleThemeModeChange}
                        label=""
                      />
                    </FormGroup>
                  </Grid>
                  <Grid>
                    {userRole &&
                    adminRole &&
                    userRole?.role === "user" &&
                    adminRole?.role === "admin" ? (
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          changleRole(adminRole._id);
                        }}
                      >
                        Switch To Admin
                      </Button>
                    ) : (
                      <Typography noWrap component="div">
                        <Box sx={{ flexGrow: 0 }}>
                          <Tooltip title="Open settings">
                            <div
                              style={{
                                display: "flex",
                                justifyItems: "center",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                              >
                                <Avatar
                                  style={{ height: "40px", width: "40px" }}
                                >
                                  {`${userData?.firstName.charAt(
                                    0
                                  )}${userData?.lastName.charAt(0)}`}
                                </Avatar>
                              </IconButton>
                            </div>
                          </Tooltip>
                          <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            {settings.map((setting, index) => (
                              <Link
                                key={index}
                                to={setting.link}
                                className={`${
                                  theme.palette.mode === "dark"
                                    ? "link-format-white"
                                    : "link-format-black"
                                } link-format`}
                              >
                                <div
                                  style={{ display: "flex", padding: "5px" }}
                                >
                                  <MenuItem onClick={handleCloseUserMenu}>
                                    <ListItemIcon>
                                      {<setting.icon />}
                                    </ListItemIcon>
                                    <Typography textAlign="center">
                                      {setting.title}
                                    </Typography>
                                  </MenuItem>
                                </div>
                              </Link>
                            ))}
                            <div style={{ display: "flex", padding: "5px" }}>
                              <MenuItem onClick={() => handleLogout()}>
                                <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
                                <Typography textAlign="center">
                                  Logout
                                </Typography>
                              </MenuItem>
                            </div>
                          </Menu>
                        </Box>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Box
        className="sidebar"
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box" },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="sidebarDIV"
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box" },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
