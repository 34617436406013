import React from "react";
import Layout from "../layout/Layout";

function Home({ setThemeMode, themeMode }: any) {
  return (
    <div>
      <Layout setThemeMode={setThemeMode} themeMode={themeMode} />
    </div>
  );
}

export default Home;
