export const ENV_PRODUCTION = "production";
export const ENV_DEVELOPMENT = "dev";

export const LOCALSTORAGE_USER_AUTH_TOKEN_KEY = "user-token";
export const LOCALSTORAGE_USER_AUTH_DETAILS_KEY = "user-details";
export const LOCALSTORAGE_ADMIN_AUTH_DETAILS = "role-credentials";
export const THEME = "theme";

export const SERVER_AUTH_ERROR_STATUS_CODE = 401;
export const SERVER_VALIDATION_STATUS_CODE = 417;
export const GENERIC_ERROR_MESSAGE =
  "Something went wrong! Please try again later.";

// amazon-ivs-player
export const MOBILE_BREAKPOINT = 875;
export const VOD_LOADING_TIMEOUT = 15000; // 15s
export const LIVE = "live";
export const VOD = "vod";
