import { createSlice } from "@reduxjs/toolkit";

export interface ChannelSchema {
  channelName: string;
  deviceName: string;
  camera: string;
  channelArn: string;
  _id: string;
  userId: string;
  isReserve: boolean;
  channelReference: string;
  createdAt: string;
  playbackUrl: string;
  status: string;
  bitrate: number | string;
  whiteBalance: number | string;
  streamKey: string;
  ingestEndpoint: string;
}
export type ChannelWorkplace = ChannelSchema[];

interface CameraSchema {
  id: string;
  camera: string;
}

type CameraInterface = CameraSchema[];

interface ChannelInterface {
  channelDetailLoading: boolean;
  channelDetail: ChannelWorkplace;
  channelDetailError: string;

  unReservedChannelLoading: boolean;
  unReservedChannel: ChannelWorkplace;
  unReservedChannelError: string;

  cameraDetailLoading: boolean;
  cameraDetail: CameraInterface;
  cameraDetailError: string;

  addChannelLoading: boolean;
  addChannelMessage: string;
  addChannelError: string;

  deleteChannelLoading: boolean;
  deleteChannelMessage: string;
  deleteChannelError: string;

  updateChannelLoading: boolean;
  updateChannelMessage: string;
  updateChannelError: string;

  updateChannelRangeLoading: boolean;
  updateChannelRange: ChannelSchema | null;
  updateChannelRangeError: string;
}

const initialState: ChannelInterface = {
  channelDetailLoading: true,
  channelDetail: [],
  channelDetailError: "",

  cameraDetailLoading: false,
  cameraDetail: [],
  cameraDetailError: "",

  unReservedChannelLoading: false,
  unReservedChannel: [],
  unReservedChannelError: "",

  addChannelLoading: false,
  addChannelMessage: "",
  addChannelError: "",

  deleteChannelLoading: false,
  deleteChannelMessage: "",
  deleteChannelError: "",

  updateChannelLoading: false,
  updateChannelMessage: "",
  updateChannelError: "",

  updateChannelRangeLoading: false,
  updateChannelRange: null,
  updateChannelRangeError: "",
};

export const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    // Get Channel Data
    getChannelDataRequest: (state) => {
      state.channelDetailLoading = true;
      state.channelDetailError = "";
    },
    getChannelDataSuccess: (state, action) => {
      state.channelDetailLoading = false;
      state.channelDetail = action.payload;
    },
    getChannelDataError: (state, action) => {
      state.channelDetailLoading = false;
      state.channelDetailError = action.payload;
    },

    // add Channel Data
    addChannelDataRequest: (state) => {
      state.addChannelLoading = true;
      state.addChannelError = "";
    },
    addChannelDataSuccess: (state, action) => {
      state.addChannelLoading = false;
      state.addChannelMessage = action.payload;
    },
    addChannelDataError: (state, action) => {
      state.addChannelLoading = false;
      state.addChannelError = action.payload;
    },

    // delete Channel Data
    deleteChannelDataRequest: (state) => {
      state.deleteChannelLoading = true;
      state.deleteChannelError = "";
    },
    deleteChannelDataSuccess: (state, action) => {
      state.deleteChannelLoading = false;
      state.deleteChannelMessage = action.payload;
    },
    deleteChannelDataError: (state, action) => {
      state.deleteChannelLoading = false;
      state.deleteChannelError = action.payload;
    },

    // update Channel Data
    updateChannelDataRequest: (state) => {
      state.updateChannelLoading = true;
      state.updateChannelError = "";
    },
    updateChannelDataSuccess: (state, action) => {
      state.updateChannelLoading = false;
      state.updateChannelMessage = action.payload;
    },
    updateChannelDataError: (state, action) => {
      state.updateChannelLoading = false;
      state.updateChannelError = action.payload;
    },

    resetChannelMessage: (state) => {
      state.addChannelMessage = "";
      state.addChannelError = "";
      state.deleteChannelMessage = "";
      state.deleteChannelError = "";
      state.updateChannelMessage = "";
      state.updateChannelError = "";
    },

    // Get Camera Data
    getCameraDataRequest: (state) => {
      state.cameraDetailLoading = true;
      state.cameraDetailError = "";
    },
    getCameraDataSuccess: (state, action) => {
      state.cameraDetailLoading = false;
      state.cameraDetail = action.payload;
    },
    getCameraDataError: (state, action) => {
      state.cameraDetailLoading = false;
      state.cameraDetailError = action.payload;
    },

    // Get Camera Data
    updateChannelRangeRequest: (state) => {
      state.updateChannelRangeLoading = true;
      state.updateChannelRangeError = "";
    },
    updateChannelRangeSuccess: (state, action) => {
      state.updateChannelRangeLoading = false;
      state.updateChannelRange = action.payload;
    },
    updateChannelRangeError: (state, action) => {
      state.updateChannelRangeLoading = false;
      state.updateChannelRangeError = action.payload;
    },

    // get un-reserved channel
    unReservedChannelRequest: (state) => {
      state.unReservedChannelLoading = true;
      state.unReservedChannelError = "";
    },
    unReservedChannelSuccess: (state, action) => {
      state.unReservedChannel = action.payload;
      state.unReservedChannelLoading = false;
    },
    unReservedChannelError: (state, action) => {
      state.unReservedChannelError = action.payload;
      state.unReservedChannelLoading = false;
    },
  },
});

export const {
  getChannelDataRequest,
  getChannelDataSuccess,
  getChannelDataError,

  getCameraDataRequest,
  getCameraDataSuccess,
  getCameraDataError,

  addChannelDataRequest,
  addChannelDataSuccess,
  addChannelDataError,

  deleteChannelDataRequest,
  deleteChannelDataSuccess,
  deleteChannelDataError,

  updateChannelDataRequest,
  updateChannelDataSuccess,
  updateChannelDataError,

  updateChannelRangeRequest,
  updateChannelRangeSuccess,
  updateChannelRangeError,

  unReservedChannelRequest,
  unReservedChannelSuccess,
  unReservedChannelError,

  resetChannelMessage,
} = channelSlice.actions;

export default channelSlice.reducer;
