import { AppDispatch } from "../../../config/store";
import { UserServices } from "../services/userServices";
import {
	getOneUserDetailError,
	getOneUserDetailRequest,
	getOneUserDetailSuccess,
	userDetailsError,
	userDetailsRequest,
	userDetailsSuccess,
} from "../slices/userSlice";

export function getAllUserDetails(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(userDetailsRequest());
		UserServices.getAllUserWithPagination(payload)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(userDetailsSuccess(data));
				} else dispatch(userDetailsError(error));
			})
			.catch((error) => {
				dispatch(userDetailsError(error));
			});
	};
}

export function getOneUserDetail(user_id: string) {
	return (dispatch: AppDispatch) => {
		dispatch(getOneUserDetailRequest());
		UserServices.getOneUserDetail(user_id)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(getOneUserDetailSuccess(data));
				} else dispatch(getOneUserDetailError(error));
			})
			.catch((error) => {
				dispatch(getOneUserDetailError(error));
			});
	};
}
