import notFound from "../assets/images/not-found.png";

function NotFound() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<img src={notFound} className='not-found ' />
			<div style={{ fontSize: "20px", fontWeight: "bold" }}>
        Ups!... no results found
			</div>
		</div>
	);
}

export default NotFound;
