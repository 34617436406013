import { createSlice } from "@reduxjs/toolkit";

export type PairSchema = {
	_id: string;
	device: any;
	camera1: any;
	camera2: any;
	date_registered: string;
	serialCode: string;
	user: string;
	deviceName: string;
	status: string;
	action: HTMLElement;
  }[];

interface PairInterface {
  pairDetailLoading: boolean;
  pairDetail: PairSchema;
  pairDetailError: string;

  unReservedPairLoading: boolean;
  unReservedPair: PairSchema;
  unReservedPairError: string;

  addPairLoading: boolean;
  addPairMessage: string;
  addPairError: string;

  deletePairLoading: boolean;
  deletePairMessage: string;
  deletePairError: string;

  deleteSinglePairLoading: boolean;
  deleteSinglePairMessage: string;
  deleteSinglePairError: string;
}

const initialState: PairInterface = {
	pairDetailLoading: false,
	pairDetail: [],
	pairDetailError: "",

	unReservedPairLoading: false,
	unReservedPair: [],
	unReservedPairError: "",

	addPairLoading: false,
	addPairMessage: "",
	addPairError: "",

	deletePairLoading: false,
	deletePairMessage: "",
	deletePairError: "",

	deleteSinglePairLoading: false,
	deleteSinglePairMessage: "",
	deleteSinglePairError: "",
};

export const pairSlice = createSlice({
	name: "pairs",
	initialState,
	reducers: {
		// Get Channel Data
		getPairDataRequest: (state) => {
			state.pairDetailLoading = false;
			state.pairDetailError = "";
		},
		getPairDataSuccess: (state, action) => {
			state.pairDetailLoading = false;
			state.pairDetail = action.payload;
		},
		getPairDataError: (state, action) => {
			state.pairDetailLoading = false;
			state.pairDetailError = action.payload;
		},

		// add pair Data
		addPairDataRequest: (state) => {
			state.addPairLoading = true;
			state.addPairError = "";
		},
		addPairDataSuccess: (state, action) => {
			state.addPairLoading = false;
			state.addPairMessage = action.payload;
		},
		addPairDataError: (state, action) => {
			state.addPairLoading = false;
			state.addPairError = action.payload;
		},

		// delete pair Data
		deletePairDataRequest: (state) => {
			state.deletePairLoading = true;
			state.deletePairError = "";
		},
		deletePairDataSuccess: (state, action) => {
			state.deletePairLoading = false;
			state.deletePairMessage = action.payload;
		},
		deletePairDataError: (state, action) => {
			state.deletePairLoading = false;
			state.deletePairError = action.payload;
		},

		// delete pair Data
		deleteSinglePairDataRequest: (state) => {
			state.deleteSinglePairLoading = true;
			state.deleteSinglePairError = "";
		},
		deleteSinglePairDataSuccess: (state, action) => {
			state.deleteSinglePairLoading = false;
			state.deleteSinglePairMessage = action.payload;
		},
		deleteSinglePairDataError: (state, action) => {
			state.deleteSinglePairLoading = false;
			state.deleteSinglePairError = action.payload;
		},

		// get un-reserved channel
		unReservedPairRequest: (state) => {
			state.unReservedPairLoading = true;
			state.unReservedPairError = "";
		},
		unReservedPairSuccess: (state, action) => {
			state.unReservedPair = action.payload;
			state.unReservedPairLoading = false;
		},
		unReservedPairError: (state, action) => {
			state.unReservedPairError = action.payload;
			state.unReservedPairLoading = false;
		},

		resetPairMessage: (state) => {
			state.addPairMessage = "";
			state.addPairError = "";
			state.deletePairMessage = "";
			state.deletePairError = "";
			state.deleteSinglePairMessage = "";
			state.deleteSinglePairError = "";
		},
	},
});

export const {
	getPairDataRequest,
	getPairDataSuccess,
	getPairDataError,

	addPairDataRequest,
	addPairDataSuccess,
	addPairDataError,

	deletePairDataRequest,
	deletePairDataSuccess,
	deletePairDataError,

	unReservedPairRequest,
	unReservedPairSuccess,
	unReservedPairError,

	deleteSinglePairDataRequest,
	deleteSinglePairDataSuccess,
	deleteSinglePairDataError,

	resetPairMessage,
} = pairSlice.actions;

export default pairSlice.reducer;
