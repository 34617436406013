import { createSlice } from "@reduxjs/toolkit";

interface AuthInterface {
  loginLoading: boolean;
  loggedInUser: object | null;
  loginMessage: string;
  loginError: string;

  signUpLoading: boolean;
  signUpUser: object | null;
  signUpMessage: string;
  signUpError: string;

  forgotPasswordLoading: boolean;
  forgotPasswordError: string;
  forgotPasswordDetails: string;

  resetPasswordLoading: boolean;
  resetPasswordError: string;
  resetPasswordDetails: string;
}

const initialState: AuthInterface = {
	loginLoading: false,
	loggedInUser: null,
	loginMessage: "",
	loginError: "",

	signUpLoading: false,
	signUpUser: null,
	signUpMessage: "",
	signUpError: "",

	forgotPasswordLoading: false,
	forgotPasswordError: "",
	forgotPasswordDetails: "",

	resetPasswordLoading: false,
	resetPasswordError: "",
	resetPasswordDetails: "",
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		// Login
		loginRequest: (state) => {
			state.loginLoading = true;
			state.loginMessage = "";
			state.loginError = "";
		},
		loginSuccess: (state, action) => {
			state.loginLoading = false;
			state.loggedInUser = action.payload.userObj;
			state.loginMessage = action.payload.message;
		},
		loginError: (state, action) => {
			state.loginLoading = false;
			state.loginError = action.payload;
		},
		resetMessage: (state) => {
			state.loggedInUser = null;
			state.loginMessage = "";
			state.loginError = "";
		},

		// Login
		signUpRequest: (state) => {
			state.signUpLoading = true;
			state.signUpMessage = "";
			state.signUpError = "";
		},
		signUpSuccess: (state, action) => {
			state.signUpLoading = false;
			state.signUpUser = action.payload.userObj;
			state.signUpMessage = action.payload.message;
		},
		signUpError: (state, action) => {
			state.signUpLoading = false;
			state.signUpError = action.payload;
		},
		resetSignUpMessage: (state) => {
			state.signUpMessage = "";
			state.signUpError = "";
		},

		// Forgot Password
		forgotPasswordRequest: (state) => {
			state.forgotPasswordLoading = true;
			state.forgotPasswordDetails = "";
			state.forgotPasswordError = "";
		},
		forgotPasswordSuccess: (state, action) => {
			state.forgotPasswordLoading = false;
			state.forgotPasswordDetails = action.payload;
		},
		forgotPasswordError: (state, action) => {
			state.forgotPasswordLoading = false;
			state.forgotPasswordDetails = "";
			state.forgotPasswordError = action.payload;
		},
		resetStateForgotPassword: (state) => {
			state.forgotPasswordDetails = "";
			state.forgotPasswordError = "";
		},

		// Forgot Password
		resetPasswordRequest: (state) => {
			state.resetPasswordLoading = true;
			state.resetPasswordDetails = "";
		},
		resetPasswordSuccess: (state, action) => {
			state.resetPasswordLoading = false;
			state.resetPasswordDetails = action.payload.message;
		},
		resetPasswordError: (state, action) => {
			state.resetPasswordLoading = false;
			state.resetPasswordDetails = "";
			state.resetPasswordError = action.payload;
		},

		resetResetPassword: (state) => {
			state.resetPasswordDetails = "";
			state.resetPasswordError = "";
		},
	},
});

export const {
	loginRequest,
	loginSuccess,
	loginError,
	resetMessage,

	signUpRequest,
	signUpSuccess,
	signUpError,
	resetSignUpMessage,

	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordError,
	resetStateForgotPassword,

	resetPasswordRequest,
	resetPasswordSuccess,
	resetPasswordError,
	resetResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
