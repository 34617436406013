import { createSlice } from "@reduxjs/toolkit";

interface UserInterface {
  firstName: string;
  lastName: string;
  email: string;
  profile_image: string;
}

interface ProfileInterface {
  logoutUserLoading: boolean;
  logoutUserError: string;
  logoutUserDetails: string;

  getUserLoading: boolean;
  getUserError: string;
  getUserDetails: UserInterface | null;

  changePasswordLoading: boolean;
  changePasswordError: string;
  changePasswordDetails: string;

  changeProfileLoading: boolean;
  changeProfileError: string;
  changeProfileDetails: string;

  updateProfileImageLoading: boolean;
  updateProfileImageError: string;
  updateProfileImageDetails: string;
}

const initialState: ProfileInterface = {
	logoutUserLoading: false,
	logoutUserError: "",
	logoutUserDetails: "",

	getUserLoading: false,
	getUserError: "",
	getUserDetails: null,

	changePasswordLoading: false,
	changePasswordError: "",
	changePasswordDetails: "",

	changeProfileLoading: false,
	changeProfileError: "",
	changeProfileDetails: "",

	updateProfileImageLoading: false,
	updateProfileImageError: "",
	updateProfileImageDetails: "",
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		// Change Password
		logoutUserRequest: (state) => {
			state.logoutUserLoading = true;
			state.logoutUserDetails = "";
		},
		logoutUserSuccess: (state, action) => {
			state.logoutUserLoading = false;
			state.logoutUserDetails = action.payload.message;
		},
		logoutUserError: (state, action) => {
			state.logoutUserLoading = false;
			state.logoutUserDetails = "";
			state.logoutUserError = action.payload;
		},
		resetLogoutUser: (state) => {
			state.logoutUserLoading = false;
			state.logoutUserDetails = "";
			state.logoutUserError = "";
		},
		// get users details
		getUserDetailsRequest: (state) => {
			state.getUserLoading = true;
			state.getUserError = "";
		},
		getUserDetailsSuccess: (state, action) => {
			state.getUserLoading = false;
			state.getUserDetails = action.payload;
		},
		getUserDetailsError: (state, action) => {
			state.getUserLoading = false;
			state.getUserError = action.payload;
		},
		// Change Password
		changePasswordRequest: (state) => {
			state.changePasswordLoading = true;
			state.changePasswordDetails = "";
		},
		changePasswordSuccess: (state, action) => {
			state.changePasswordLoading = false;
			state.changePasswordDetails = action.payload.message;
		},
		changePasswordError: (state, action) => {
			state.changePasswordLoading = false;
			state.changePasswordDetails = "";
			state.changePasswordError = action.payload;
		},
		// Change Profile
		changeProfileRequest: (state) => {
			state.changeProfileLoading = true;
			state.changeProfileDetails = "";
		},
		changeProfileSuccess: (state, action) => {
			state.changeProfileLoading = false;
			state.changeProfileDetails = action.payload.message;
		},
		changeProfileError: (state, action) => {
			state.changeProfileLoading = false;
			state.changeProfileDetails = "";
			state.changeProfileError = action.payload;
		},
		// Upload Profile Image
		updateProfileImageRequest: (state) => {
			state.updateProfileImageLoading = true;
			state.updateProfileImageDetails = "";
		},
		updateProfileImageSuccess: (state, action) => {
			state.updateProfileImageLoading = false;
			state.updateProfileImageDetails = action.payload.message;
		},
		updateProfileImageError: (state, action) => {
			state.updateProfileImageLoading = false;
			state.updateProfileImageDetails = "";
			state.updateProfileImageError = action.payload;
		},
	},
});

export const {
	logoutUserRequest,
	logoutUserSuccess,
	logoutUserError,
	resetLogoutUser,
	getUserDetailsRequest,
	getUserDetailsSuccess,
	getUserDetailsError,
	changePasswordRequest,
	changePasswordSuccess,
	changePasswordError,
	changeProfileRequest,
	changeProfileSuccess,
	changeProfileError,
	updateProfileImageRequest,
	updateProfileImageSuccess,
	updateProfileImageError,
} = profileSlice.actions;

export default profileSlice.reducer;
