import { AppDispatch } from "../../../config/store";
import LocalstorageService from "../../../helper/localstorage-services";
import ProfileService from "../services/profile";
import {
	changePasswordError,
	changePasswordRequest,
	changePasswordSuccess,
	changeProfileError,
	changeProfileRequest,
	changeProfileSuccess,
	getUserDetailsError,
	getUserDetailsRequest,
	getUserDetailsSuccess,
	logoutUserError,
	logoutUserRequest,
	logoutUserSuccess,
	updateProfileImageError,
	updateProfileImageRequest,
	updateProfileImageSuccess,
} from "../slices/profileSlice";

export function logoutUser() {
	return (dispatch: AppDispatch) => {
		dispatch(logoutUserRequest());
		ProfileService.logoutUser()
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					LocalstorageService.logoutUser();
					dispatch(logoutUserSuccess({ ...data, message }));
				} else {
					dispatch(logoutUserError(error));
				}
			})
			.catch((error) => {
				dispatch(logoutUserError(error));
			});
	};
}

export function getLoggedUserDetails() {
	return (dispatch: AppDispatch) => {
		dispatch(getUserDetailsRequest());
		ProfileService.getUserDetails()
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(getUserDetailsSuccess({ ...data, message }));
				} else {
					dispatch(getUserDetailsError(error));
				}
			})
			.catch((error) => {
				dispatch(getUserDetailsError(error));
			});
	};
}

export function updatePassword(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(changePasswordRequest());
		ProfileService.updatePassword(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(changePasswordSuccess({ ...data, message }));
				} else {
					dispatch(changePasswordError(error));
				}
			})
			.catch((error) => {
				dispatch(changePasswordError(error));
			});
	};
}

export function updateProfile(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(changeProfileRequest());
		ProfileService.updateProfile(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(changeProfileSuccess({ ...data, message }));
				} else {
					dispatch(changeProfileError(error));
				}
			})
			.catch((error) => {
				dispatch(changeProfileError(error));
			});
	};
}
export function updateProfileImage(payload: FormData) {
	return (dispatch: AppDispatch) => {
		dispatch(updateProfileImageRequest());
		ProfileService.updateProfileImage(payload)
			.then((response) => {
				const { success, data, message, error } = response.data;
				if (success) {
					dispatch(updateProfileImageSuccess({ ...data, message }));
					// navigate(ROUTE_URLS.LOGIN);
				} else {
					dispatch(updateProfileImageError(error));
				}
			})
			.catch((error) => {
				dispatch(updateProfileImageError(error));
			});
	};
}
