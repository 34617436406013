import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../components/auth/slices/authSlice";
import deviceSlice from "../components/DeviceManagement/slices/deviceSlice";
import profileSlice from "../components/profile/slices/profileSlice";
import channelSlice from "../components/Channel/slices/channelSlice";
import pairSlice from "../components/Pairs/slices/pairSlice";
import broadcastSlice from "../components/BroadcastManagement/slices/broadcastSlice";
import userSlice from "../components/UsersManagement/slices/userSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    device: deviceSlice,
    profile: profileSlice,
    channel: channelSlice,
    pairs: pairSlice,
    broadcast: broadcastSlice,
    users: userSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
