import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../config/store";
import { getAllChannel } from "../../Channel/middleware/channel";
import { getUpdateDeviceData } from "../middleware/device";

interface ChannelSelectionProps {
  id: string;
  channels: {
    camera1: string;
    camera2: string;
  };
  userId: string;
}

const ChannelSelection = ({ id, channels, userId }: ChannelSelectionProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { channelDetail } = useSelector((state: RootState) => state.channel);

  // Initialize with IDs, but display names if available
  const [camera1, setCamera1] = useState<string>(channels.camera1 || "");
  const [camera2, setCamera2] = useState<string>(channels.camera2 || "");
  const [camera1Name, setCamera1Name] = useState<string | null>(null);
  const [camera2Name, setCamera2Name] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getAllChannel(userId));
  }, [userId, dispatch]);

  // Set initial names based on channelDetail when available
  useEffect(() => {
    if (channelDetail.length > 0) {
      const camera1Channel = channelDetail.find(
        (channel) => channel._id === camera1
      );
      const camera2Channel = channelDetail.find(
        (channel) => channel._id === camera2
      );
      setCamera1Name(camera1Channel ? camera1Channel.channelName : "");
      setCamera2Name(camera2Channel ? camera2Channel.channelName : "");
    }
  }, [channelDetail, camera1, camera2]);

  const handleCamera1Change = (e: any) => {
    const newCamera1 = e.target.value;
    setCamera1(newCamera1);
    setCamera1Name(
      channelDetail.find((channel) => channel._id === newCamera1)
        ?.channelName ?? null
    );
    dispatch(
      getUpdateDeviceData(id, {
        channels: {
          camera1: newCamera1,
          camera2,
        },
      })
    );
  };

  const handleCamera2Change = (e: any) => {
    const newCamera2 = e.target.value;
    setCamera2(newCamera2);
    setCamera2Name(
      channelDetail.find((channel) => channel._id === newCamera2)
        ?.channelName ?? null
    );
    dispatch(
      getUpdateDeviceData(id, {
        channels: {
          camera1,
          camera2: newCamera2,
        },
      })
    );
  };

  return (
    <>
      <div style={{ marginBottom: "6px" }}>
        <label>Camera 1: </label>
        <Select
          value={camera1}
          onChange={handleCamera1Change}
          fullWidth
          displayEmpty
          renderValue={() => (camera1Name ? camera1Name : "Select Camera 1")}
        >
          {channelDetail.map(
            (channel) =>
              channel._id !== camera2 && (
                <MenuItem key={channel._id} value={channel._id}>
                  {channel.channelName}
                </MenuItem>
              )
          )}
        </Select>
      </div>
      <div>
        <label>Camera 2: </label>
        <Select
          value={camera2}
          onChange={handleCamera2Change}
          fullWidth
          displayEmpty
          renderValue={() => (camera2Name ? camera2Name : "Select Camera 2")}
        >
          {channelDetail.map(
            (channel) =>
              channel._id !== camera1 && (
                <MenuItem key={channel._id} value={channel._id}>
                  {channel.channelName}
                </MenuItem>
              )
          )}
        </Select>
      </div>
    </>
  );
};

export default ChannelSelection;
