import { AppDispatch } from "../../../config/store";
import ChannelService from "../services/channelServices";
import {
  addChannelDataError,
  addChannelDataRequest,
  addChannelDataSuccess,
  deleteChannelDataError,
  deleteChannelDataRequest,
  deleteChannelDataSuccess,
  getCameraDataError,
  getCameraDataRequest,
  getCameraDataSuccess,
  getChannelDataError,
  getChannelDataRequest,
  getChannelDataSuccess,
  unReservedChannelError,
  unReservedChannelRequest,
  unReservedChannelSuccess,
  updateChannelDataError,
  updateChannelDataRequest,
  updateChannelDataSuccess,
  updateChannelRangeError,
  updateChannelRangeRequest,
  updateChannelRangeSuccess,
} from "../slices/channelSlice";

export function getAllChannel(user_id: string) {
  return (dispatch: AppDispatch) => {
    dispatch(getChannelDataRequest());
    ChannelService.getChannelListByID(user_id)
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(getChannelDataSuccess(data));
        } else {
          dispatch(getChannelDataError(error));
        }
      })
      .catch((error) => {
        dispatch(getChannelDataError(error));
      });
  };
}

export function getUnReservedChannel() {
  return (dispatch: AppDispatch) => {
    dispatch(unReservedChannelRequest());
    ChannelService.getUnReservedChannel()
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(unReservedChannelSuccess(data));
        } else {
          dispatch(unReservedChannelError(error));
        }
      })
      .catch((error) => {
        dispatch(unReservedChannelError(error));
      });
  };
}

export function getAllCameraDetails() {
  return (dispatch: AppDispatch) => {
    dispatch(getCameraDataRequest());
    ChannelService.getChannelList()
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(getCameraDataSuccess(data));
        } else {
          dispatch(getCameraDataError(error));
        }
      })
      .catch((error) => {
        dispatch(getCameraDataError(error));
      });
  };
}

export function addChannelDetail(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(addChannelDataRequest());
    ChannelService.addChannelDetail(payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(addChannelDataSuccess(message));
        } else {
          dispatch(addChannelDataError(error));
        }
      })
      .catch((error) => {
        dispatch(addChannelDataError(error));
      });
  };
}

export function deleteChannelDetail(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(deleteChannelDataRequest());
    ChannelService.deleteChannelDetail(payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(deleteChannelDataSuccess(message));
        } else {
          dispatch(deleteChannelDataError(error));
        }
      })
      .catch((error) => {
        dispatch(deleteChannelDataError(error));
      });
  };
}

export function updateChannelDetail(channel_id: string, payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(updateChannelDataRequest());
    ChannelService.updateChannelDetail(channel_id, payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(updateChannelDataSuccess(message));
        } else {
          dispatch(updateChannelDataError(error));
        }
      })
      .catch((error) => {
        dispatch(updateChannelDataError(error));
      });
  };
}

export function updateTemperatureRange(channel_id: string, payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(updateChannelRangeRequest());
    ChannelService.updateChannelRangeData(channel_id, payload)
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(updateChannelRangeSuccess(data));
        } else {
          dispatch(updateChannelRangeError(error));
        }
      })
      .catch((error) => {
        dispatch(updateChannelRangeError(error));
      });
  };
}
