/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import {
  dateWithMonthFormat,
  timeOnlyFormat,
} from "../../../helper/dateFormate";
import chevronRight from "../../../assets/images/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import ROUTE_URLS from "../../../config/routes";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import { getAllPairs, getUnReservedPair } from "../../Pairs/middleware/pairs";
import { useFormik } from "formik";
import { broadcastValidation } from "../validation/broadCastValidation";
import {
  addBroadcast,
  broadcastDetailList,
  deleteBroadcastDetail,
  getOneBroadcastDetail,
  updateBroadcastDetail,
} from "../middleware/broadcast";
import ToastService from "../../../helper/toast-services";
import {
  resetBroadcastMessage,
  setBroadcastChannelLive,
} from "../slices/broadcastSlice";
import DeleteModal from "../../../common/DeleteModal";
import LocalstorageService from "../../../helper/localstorage-services";

export interface LiveStatuses {
  [key: string]: boolean;
}
interface Data {
  title: string;
  date: string;
  time: string;
  status: string;
  action: HTMLElement;
}

interface BroadcastSchema {
  pairs: Array<string>;
  title: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

export const getStyles = (name: string, personName: string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const Broadcast = () => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const {
    pairDetail,
    pairDetailLoading,
    unReservedPairLoading,
    unReservedPair,
  } = useSelector((state: RootState) => state.pairs);
  const {
    broadcastDetailLoading,
    broadcastDetail,
    broadcastLive,

    addBroadcastDetailLoading,
    addBroadcastDetailMessage,
    addBroadcastDetailError,

    deleteBroadcastLoading,
    deleteBroadcastMessage,
    deleteBroadcastError,

    getOneBroadcastLoading,
    getOneBroadcast,

    updateBroadcastLoading,
    updateBroadcastMessage,
    updateBroadcastError,
  } = useSelector((state: RootState) => state.broadcast);

  const dispatch: AppDispatch = useDispatch();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [isOpenAdd, setIsOpenAdd] = React.useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);
  const [isReserved, setIsReserved] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pairsData, setPairsData] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const userData = LocalstorageService.getLoggedInUserDetails();
  // const [liveStatuses, setLiveStatuses] = useState<LiveStatuses>({});

  useEffect(() => {
    if (getOneBroadcast !== null && !getOneBroadcastLoading) {
      formik.setFieldValue("title", getOneBroadcast.title);
      formik.setFieldValue("pairs", getOneBroadcast.pairs);
      setPersonName(getOneBroadcast?.pairs);
    }
    // eslint-disable-next-line
  }, [getOneBroadcast]);

  useEffect(() => {
    if (
      broadcastDetail &&
      !broadcastDetailLoading &&
      !addBroadcastDetailLoading &&
      !deleteBroadcastMessage &&
      initialLoading
    ) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [broadcastDetail]);

  useEffect(() => {
    dispatch(broadcastDetailList(userData._id));
    // eslint-disable-next-line
  }, [
    addBroadcastDetailMessage,
    deleteBroadcastMessage,
    updateBroadcastMessage,
  ]);

  useEffect(() => {
    if (addBroadcastDetailMessage || updateBroadcastMessage) {
      ToastService.success(addBroadcastDetailMessage || updateBroadcastMessage);
      handleCloseAddModal();
    }
    if (deleteBroadcastMessage) {
      ToastService.success(deleteBroadcastMessage);
      handleCloseDeleteModal();
    }
    // eslint-disable-next-line
  }, [
    addBroadcastDetailMessage,
    deleteBroadcastMessage,
    updateBroadcastMessage,
  ]);

  const initialValues: BroadcastSchema = {
    pairs: [],
    title: "",
  };

  useEffect(() => {
    if (!pairDetailLoading || !unReservedPairLoading) {
      if (isReserved) {
        setPairsData(unReservedPair);
      } else {
        setPairsData(pairDetail);
      }
    }
  }, [unReservedPair, pairDetail]);

  const handleSubmit = (values: BroadcastSchema) => {
    if (getOneBroadcast) {
      dispatch(updateBroadcastDetail(getOneBroadcast._id, values));
    } else {
      dispatch(addBroadcast(values));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: broadcastValidation,
  });

  const handleCloseAddModal = () => {
    setPairsData([]);
    setIsOpenAdd(false);
    dispatch(resetBroadcastMessage());
    formik.resetForm();
  };

  const handleDeleteChange = (id: string) => {
    if (selected.includes(id)) {
      const data = [...selected];
      const dataIndex = data.findIndex((o) => {
        return o === id;
      });
      data.splice(dataIndex, 1);
      setSelected(data);
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDelete = () => {
    dispatch(deleteBroadcastDetail({ id: selected }));
  };

  const handleEdit = (id: string) => {
    setIsReserved(false);
    dispatch(getAllPairs(userData._id));
    setIsOpenAdd(true);
    dispatch(getOneBroadcastDetail(id));
  };

  const identifyIsLive = async (url: string) => {
    try {
      const response = await fetch(
        `https://${url}/recording-started-latest.json`
      );
      const data = await response.json();
      return data.isChannelLive;
    } catch (error) {
      console.error("Error fetching data", error);
      return false;
    }
  };

  // const url = ["dbbt2ttfme8k1.cloudfront.net", "dbvh0b9kdk7ga.cloudfront.net"];

  useEffect(() => {
    const fetchLiveStatuses = async () => {
      const statuses: LiveStatuses = {};
      for (const row of broadcastDetail) {
        if (row?.pairs[0]?.camera1?.distributionDomainName) {
          const isLive = await identifyIsLive(
            row?.pairs[0]?.camera1?.distributionDomainName
          );
          statuses[row?.pairs[0]?.camera1?._id] = isLive;
        }
        if (row?.pairs[0]?.camera2?.distributionDomainName) {
          const isLive = await identifyIsLive(
            row?.pairs[0]?.camera2?.distributionDomainName
          );
          statuses[row?.pairs[0]?.camera2?._id] = isLive;
        }
      }
      // setLiveStatuses(statuses);
      dispatch(setBroadcastChannelLive(statuses));
    };
    if (broadcastDetail && broadcastDetail?.length > 0) {
      fetchLiveStatuses();
    }
  }, [broadcastDetail]);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    formik.setFieldValue(
      "pairs",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDelete(false);
    setSelected([]);
    dispatch(resetBroadcastMessage());
  };

  return (
    <>
      <div className="mainPageHeading">
        <h1
          className={`${
            theme.palette.mode === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Broadcast
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            size="large"
            // onClick={() => {
            //   navigate(`${ROUTE_URLS.BROADCAST_HISTORY}`);
            // }}
            className="loginButton"
          >
            History &nbsp;
            <HistoryIcon />
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            style={{ width: "260px" }}
            onClick={() => {
              setIsReserved(true);
              dispatch(getUnReservedPair());
              setIsOpenAdd(true);
            }}
            className="loginButton"
          >
            <AddIcon />
            Create Broadcast
          </Button>
          <Button
            color="primary"
            variant="outlined"
            sx={{
              borderColor: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              color: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              "&:hover": {
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
              },
            }}
            type="submit"
            size="large"
            onClick={() => setIsOpenDelete(true)}
            disabled={deleteBroadcastLoading}
            className="loginButton"
          >
            Delete
          </Button>
        </div>
      </div>
      {broadcastDetailLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        <span
                          className={`${
                            theme.palette.mode === "light"
                              ? "login-text-dark"
                              : ""
                          } tableHead`}
                        >
                          {headCell.label}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {broadcastDetail &&
                    broadcastDetail.length > 0 &&
                    broadcastDetail.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              onChange={() => handleDeleteChange(row._id)}
                              checked={
                                selected.includes(row._id) ? true : false
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {" "}
                              {row.title}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {timeOnlyFormat(row.createdAt)}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {dateWithMonthFormat(row.createdAt)}
                            </p>
                          </TableCell>
                          {/* <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.status ? (
                              <Button
                                onClick={() => {
                                  navigate(
                                    `${ROUTE_URLS.BROADCAST_STREAM}/${row._id}/${row.pairs[0].camera1}`
                                  );
                                }}
                                variant="contained"
                                color="success"
                                size="small"
                              >
                                Live
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  navigate(
                                    `${ROUTE_URLS.BROADCAST_STREAM}/${row._id}/${row.pairs[0].camera1}`
                                  );
                                }}
                                variant="contained"
                                color="error"
                                size="small"
                              >
                                offline
                              </Button>
                            )}
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <div>
                              {broadcastLive &&
                              (broadcastLive[row?.pairs[0]?.camera1?._id] ||
                                broadcastLive[row?.pairs[0]?.camera2?._id]) ? (
                                <Button
                                  onClick={() => {
                                    navigate(
                                      `${ROUTE_URLS.BROADCAST_STREAM}/${row._id}/${row.pairs[0]?.camera1?._id}`
                                    );
                                  }}
                                  variant="contained"
                                  color="success"
                                  size="small"
                                >
                                  Live
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    navigate(
                                      `${ROUTE_URLS.BROADCAST_STREAM}/${row._id}/${row.pairs[0]?.camera1?._id}`
                                    );
                                  }}
                                  variant="contained"
                                  color="error"
                                  size="small"
                                >
                                  offline
                                </Button>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <div
                              onClick={() => handleEdit(row._id)}
                              className="tableLink"
                            >
                              <span>Manage Broadcast</span>
                              <img src={chevronRight} alt="details" />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {broadcastDetail && broadcastDetail.length === 0 && (
              <span
                className={`${
                  theme.palette.mode === "light" ? "login-text-dark" : ""
                } no-data-found tableRows`}
              >
                No Broadcast Found
              </span>
            )}
          </Paper>
        </Box>
      )}

      <Dialog
        fullWidth
        open={isOpenAdd}
        onClose={handleCloseAddModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Broadcast</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseAddModal}
            />
          </div>
        </DialogTitle>
        {getOneBroadcastLoading ||
        pairDetailLoading ||
        unReservedPairLoading ? (
          <div className="spinnerDiv">
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              {(addBroadcastDetailError || updateBroadcastError) && (
                <Alert severity="error">
                  {addBroadcastDetailError || updateBroadcastError}
                </Alert>
              )}
              <TextField
                fullWidth
                variant="outlined"
                id="title"
                name="title"
                label="Broadcast Title"
                placeholder="Enter Broadcast Title"
                type={"text"}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <FormControl
                style={{ marginTop: "20px" }}
                fullWidth
                error={formik.touched.pairs && Boolean(formik.errors.pairs)}
              >
                <InputLabel id="demo-simple-select-error-label">
                  Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="pairs"
                  value={personName}
                  multiple
                  label="Device"
                  name="pairs"
                  onChange={handleChange}
                  input={<OutlinedInput label="Device" />}
                  MenuProps={MenuProps}
                  onBlur={formik.handleBlur}
                >
                  {pairsData &&
                    pairsData.length > 0 &&
                    pairsData.map((o) => (
                      <MenuItem
                        style={getStyles(
                          o.device?.deviceName,
                          personName,
                          theme
                        )}
                        key={o._id}
                        value={o._id}
                      >
                        {o.device?.deviceName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {formik.touched.pairs && formik.errors.pairs}
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{ marginBottom: "20px", justifyContent: "center" }}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  "&:hover": {
                    borderColor: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                    color: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                  },
                }}
                type="button"
                size="large"
                style={{ width: "270px" }}
                className="loginButton"
                disabled={addBroadcastDetailLoading || updateBroadcastLoading}
                onClick={handleCloseAddModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ width: "270px" }}
                type="submit"
                size="large"
                disabled={addBroadcastDetailLoading || updateBroadcastLoading}
                className="loginButton"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <DeleteModal
        handleClose={handleCloseDeleteModal}
        handleOnDelete={handleDelete}
        isOpen={isOpenDelete}
        loading={deleteBroadcastLoading}
        deleteErrorMessage={deleteBroadcastError}
        title="Once it is deleted you will not be able to recover the data again!"
        buttonName="Delete"
      />
    </>
  );
};

export default Broadcast;
