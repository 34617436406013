import {
  Button,
  CssBaseline,
  Box,
  Container,
  Alert,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoggedUserDetails,
  updateProfileImage,
} from "../middleware/profile";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import defaultUser from "./defaultUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastService from "../../../helper/toast-services";
import { AppDispatch, RootState } from "../../../config/store";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
interface ProfileInterface {
  firstName: string;
  lastName: string;
  email: string;
  profile_image: string;
}

interface Props {
  user: ProfileInterface | null;
}

export default function UpdateProfileImage({ user }: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { getUserLoading, getUserError, updateProfileImageDetails } =
    useSelector((state: RootState) => state.profile);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [isSelectedImage, setIsSelectedImage] = useState(false);

  useEffect(() => {
    if (user !== null) {
      setSelectedImage(user?.profile_image);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (updateProfileImageDetails) {
      ToastService.success(updateProfileImageDetails);
      dispatch(getLoggedUserDetails());
    }
  }, [updateProfileImageDetails]);

  const handleSubmit = () => {
    // API dispatch calling here... //
    const formData = new FormData();
    formData.append("file", selectedImage);
    dispatch(updateProfileImage(formData));
    setIsSelectedImage(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeImage = (event: any) => {
    setIsSelectedImage(true);
    setSelectedImage(event.target.files[0]);
  };

  const handleRemoveImage = () => {
    setIsSelectedImage(false);
    setSelectedImage("");
  };

  return (
    <div>
      <Card sx={{ minHeight: "355px" }}>
        <CardContent>
          {getUserLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <CssBaseline />
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    sx={{ width: "100%" }}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    {getUserError && (
                      <Alert severity="error">{getUserError}</Alert>
                    )}
                  </Stack>

                  <form>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="profile-box">
                        {isSelectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt={user?.firstName}
                            className="profile-image"
                          />
                        ) : user?.profile_image ? (
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              user?.profile_image
                            }
                            onError={(e: any) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                            }}
                            alt={user.lastName}
                            className="profile-image"
                          />
                        ) : (
                          <img
                            alt="User"
                            src={defaultUser}
                            className="profile-image"
                          />
                        )}
                      </div>
                      <div>
                        {isSelectedImage && (
                          <div>
                            <Button
                              component="label"
                              variant="contained"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleRemoveImage()}
                            >
                              Remove
                            </Button>
                            <Button
                              style={{ marginLeft: "2px" }}
                              component="label"
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                              onClick={() => handleSubmit()}
                            >
                              Upload
                            </Button>
                          </div>
                        )}
                        {!isSelectedImage && (
                          <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onChange={(event) => {
                              onChangeImage(event);
                            }}
                          >
                            Upload file
                            <VisuallyHiddenInput type="file" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </Box>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
