import { delPayload, get, post, put } from "../../../helper";

const URI = "device-channel";

const addPairDetail = (payload: object) => {
	return post(`${URI}/add`, payload);
};

const updatePair = (pairId: string, payload: object) => {
	return put(`${URI}/update/${pairId}`, payload);
};

const listPairDetail = (user_id: string) => {
	return get(`${URI}/list?createdBy=${user_id}`);
};

const deleteById = (device_id: string, payload: object) => {
	return delPayload(`${URI}/delete/${device_id}`, payload);
};

const deletePair = (payload: object) => {
	return delPayload(`${URI}/delete`, payload);
};

const getUnReservedPairs = () => {
	return get(`${URI}/reserve`);
};

const PairsService = {
	listPairDetail,
	addPairDetail,
	updatePair,
	deleteById,
	deletePair,
	getUnReservedPairs,
};
export default PairsService;
