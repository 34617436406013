import * as yup from "yup";

interface PairValidationInterface {
  device: string;
  camera1: string;
  camera2: string;
}

export const pairValidationSchema: yup.ObjectSchema<PairValidationInterface> =
  yup.object({
  	device: yup.string().required("Device name is required"),
  	camera1: yup.string().required("Camera 1 is required"),
  	camera2: yup
  		.string()
  		.required("Camera 2 is required")
  		.notOneOf(
  			[yup.ref("cam1")],
  			"Camera 1 and Camera 2 must be different value",
  		),
  });
