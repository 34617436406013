import { AppDispatch } from "../../../config/store";
import DeviceService from "../services/device";
import {
  addDeviceDataFailed,
  addDeviceDataRequest,
  addDeviceDataSuccess,
  deleteDeviceError,
  deleteDeviceRequest,
  deleteDeviceSuccess,
  getDeviceDataFailed,
  getDeviceDataRequest,
  getDeviceDataSuccess,
  getOneDeviceError,
  getOneDeviceRequest,
  getOneDeviceSuccess,
  unReservedDeviceError,
  unReservedDeviceRequest,
  unReservedDeviceSuccess,
  updateDeviceError,
  updateDeviceRequest,
  updateDeviceSuccess,
} from "../slices/deviceSlice";

export function getDeviceData(user_id: string) {
  return (dispatch: AppDispatch) => {
    dispatch(getDeviceDataRequest());
    DeviceService.getDeviceData(user_id)
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(getDeviceDataSuccess(data));
        } else {
          dispatch(getDeviceDataFailed(error));
        }
      })
      .catch((error) => {
        dispatch(getDeviceDataFailed(error));
      });
  };
}

export function getUnReservedDevice() {
  return (dispatch: AppDispatch) => {
    dispatch(unReservedDeviceRequest());
    DeviceService.getUnReservedDevice()
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(unReservedDeviceSuccess(data));
        } else {
          dispatch(unReservedDeviceError(error));
        }
      })
      .catch((error) => {
        dispatch(unReservedDeviceError(error));
      });
  };
}

export function addDeviceData(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(addDeviceDataRequest());
    DeviceService.addDeviceData(payload)
      .then((response) => {
        const { success, data, message, error } = response.data;
        if (success) {
          dispatch(addDeviceDataSuccess({ ...data, message }));
        } else {
          dispatch(addDeviceDataFailed(error));
        }
      })
      .catch((error) => {
        dispatch(addDeviceDataFailed(error));
      });
  };
}

export function getOneDeviceData(device_id: string) {
  return (dispatch: AppDispatch) => {
    dispatch(getOneDeviceRequest());
    DeviceService.getOneDeviceData(device_id)
      .then((response) => {
        const { success, data, message } = response.data;
        if (success) {
          dispatch(getOneDeviceSuccess({ ...data, message }));
        } else {
          dispatch(getOneDeviceError(message));
        }
      })
      .catch((error) => {
        dispatch(getOneDeviceError(error));
      });
  };
}

export function getUpdateDeviceData(id: string, payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(updateDeviceRequest());
    DeviceService.getUpdateDeviceData(id, payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(updateDeviceSuccess(message));
        } else {
          dispatch(updateDeviceError(error));
        }
      })
      .catch((error) => {
        dispatch(updateDeviceError(error));
      });
  };
}

export function getDeleteDeviceData(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(deleteDeviceRequest());
    DeviceService.deleteDeviceData(payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(deleteDeviceSuccess(message));
        } else {
          dispatch(deleteDeviceError(error));
        }
      })
      .catch((error) => {
        dispatch(deleteDeviceError(error));
      });
  };
}
