import * as yup from "yup";

interface ChannelValidationInterface {
  name: string;
}

export const channelValidation: yup.ObjectSchema<ChannelValidationInterface> =
  yup.object({
  	name: yup.string().required("Channel name is required"),
  });
