import React, { ReactNode } from "react";
import { Navigate } from "react-router";
import ROUTE_URLS from "../../config/routes";
import LocalstorageService from "../localstorage-services";

interface RouteAuthGuardRestrictLoggedUserProps {
  children: ReactNode;
}

const RouteAuthGuardRestrictLoggedUser: React.FC<
  RouteAuthGuardRestrictLoggedUserProps
> = ({ children }) => {
	const token = LocalstorageService.getLoggedInUserToken();
	if (token) {
		return <Navigate to={ROUTE_URLS.DASHBOARD} replace />;
	}
	return <>{children}</>;
};

export default RouteAuthGuardRestrictLoggedUser;
