import React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Alert,
  Stack,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { forgotPasswordValidation } from "../validation/authValidationSchema";
import { useFormik } from "formik";
import ROUTE_URLS from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { forgotPassword } from "../middleware/auth";
import { useEffect } from "react";
import { resetStateForgotPassword } from "../slices/authSlice";
import footer from "../../../assets/images/loginFooter.svg";
import { AppDispatch, RootState } from "../../../config/store";
import ToastService from "../../../helper/toast-services";
import { ForgotPasswordInterface } from "../../../common/interfaces/interface";

export default function ForgotPassword() {
  const theme = useTheme();

  const dispatch: AppDispatch = useDispatch();
  const { forgotPasswordLoading, forgotPasswordError, forgotPasswordDetails } =
    useSelector((state: RootState) => state.auth);

  const initialValues: ForgotPasswordInterface = {
    email: "",
  };

  const handleSubmit = (values: ForgotPasswordInterface) => {
    // API dispatch calling here... //
    dispatch(forgotPassword(values));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: forgotPasswordValidation,
  });

  useEffect(() => {
    if (forgotPasswordDetails && !forgotPasswordError) {
      formik.resetForm();
      ToastService.success(forgotPasswordDetails);
      dispatch(resetStateForgotPassword());
    }
  }, [forgotPasswordDetails]);

  return (
    <div className="center-auth-component" style={{ height: "100vh" }}>
      <CssBaseline />
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h5 className="login-text" style={{ fontSize: "36px" }}>
            Forgot Password
          </h5>
          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            {forgotPasswordError && (
              <Alert severity="error">{forgotPasswordError}</Alert>
            )}
            {forgotPasswordDetails && (
              <Alert severity="success">{forgotPasswordDetails}</Alert>
            )}
          </Stack>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error"
                name="email"
                label="Email"
                placeholder="Enter Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                size="large"
                disabled={forgotPasswordLoading}
                className="loginButton"
              >
                {forgotPasswordLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Forgot Password"
                )}
              </Button>
              <div
                style={{
                  marginBottom: "40px",
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`${
                  theme.palette.mode === "dark"
                    ? "register-link-light"
                    : "register-link-dark"
                }`}
              >
                <Link
                  to={ROUTE_URLS.LOGIN}
                  style={{
                    display: "flex",
                    justifyItems: "center",
                  }}
                >
                  <KeyboardBackspaceIcon /> &nbsp; <span>Return to Login </span>
                </Link>
              </div>
            </div>
          </form>
        </Box>
      </div>
      <img className="footer-wave" src={footer} alt="footer" />
    </div>
  );
}
