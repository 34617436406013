import { toast } from "react-toastify";

function success(message = "Success", optionalConfig = { autoClose: 2000 }) {
	toast.success(message, {
		...optionalConfig,
	});
}

function error(message = "Error!", optionalConfig = { autoClose: 2000 }) {
	toast.error(message, optionalConfig);
}

function warning(message = "Alert!", optionalConfig = { autoClose: 2000 }) {
	toast.warn(message, optionalConfig);
}

function information(
	message = "Information",
	optionalConfig = { autoClose: 2000 },
) {
	toast.info(message, optionalConfig);
}

const ToastService = {
	success,
	error,
	warning,
	information,
};
export default ToastService;
