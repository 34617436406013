import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import { getOneUserDetail } from "../middleware/users";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  MenuProps,
  getStyles,
} from "../../BroadcastManagement/pages/Broadcast";
import ROUTE_URLS from "../../../config/routes";

const UserDetail = () => {
  const { user_id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const nav = useNavigate();
  const { oneUserDetail, oneUserDetailLoading } = useSelector(
    (state: RootState) => state.users
  );
  const [broadcastName, setBroadcastName] = React.useState<string[]>([]);

  const handleBroadcastChange = (
    event: SelectChangeEvent<typeof broadcastName>
  ) => {
    const {
      target: { value },
    } = event;
    setBroadcastName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (user_id) {
      dispatch(getOneUserDetail(user_id));
    }
  }, []);

  useEffect(() => {
    if (oneUserDetail && oneUserDetail !== null) {
      const userDetail = oneUserDetail.broadcasts.map(
        (broad: { pairs: any[] }) => {
          const data = broad.pairs.map(
            (pair: { device: { deviceName: any } }) => {
              return pair.device?.deviceName;
            }
          );
          return data;
        }
      );
      setBroadcastName(userDetail.flat());
    }
  }, [oneUserDetail]);

  return (
    <>
      {oneUserDetailLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <>
          <h1 className="pageHeading">
            {oneUserDetail
              ? `${oneUserDetail?.firstName} ${oneUserDetail?.lastName}`
              : "Users"}
          </h1>
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              {oneUserDetail &&
                oneUserDetail !== null &&
                oneUserDetail?.devices?.length > 0 && (
                  <div className="user-sub-heading">
                    <h2>Device</h2>
                    {oneUserDetail.devices.map(
                      (device: {
                        _id: React.Key | null | undefined;
                        deviceName: unknown;
                        serial: unknown;
                      }) => (
                        <Grid key={device._id} container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="deviceName"
                              name="deviceName"
                              label="Device Name"
                              placeholder="Enter deviceName number"
                              type={"text"}
                              defaultValue={device.deviceName}
                              style={{ marginBottom: "20px" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="serial"
                              name="serial"
                              label="Serial Code"
                              placeholder="Enter serial number"
                              type={"text"}
                              defaultValue={device.serial}
                            />
                          </Grid>
                        </Grid>
                      )
                    )}
                  </div>
                )}
              {oneUserDetail &&
                oneUserDetail !== null &&
                oneUserDetail?.channels?.length > 0 && (
                  <div className="user-sub-heading">
                    <h2>Channel</h2>
                    <Grid container spacing={2}>
                      {oneUserDetail?.channels.map(
                        (
                          channel: {
                            channelReference: unknown;
                            channelName: unknown;
                          },
                          i: React.Key | null | undefined
                        ) => (
                          <Grid key={i} item xs={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="channel"
                              name="channel"
                              label="Channel Name"
                              placeholder="Enter channel name"
                              type={"text"}
                              defaultValue={
                                channel.channelReference
                                  ? channel.channelReference
                                  : channel.channelName
                              }
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </div>
                )}
              {oneUserDetail &&
                oneUserDetail !== null &&
                oneUserDetail?.pairs?.length > 0 && (
                  <div className="user-sub-heading">
                    <h2>Pairs</h2>
                    {oneUserDetail?.pairs.map((pair, i) => (
                      <div key={i} style={{ marginBottom: "20px" }}>
                        <FormControl fullWidth style={{ marginBottom: "20px" }}>
                          <InputLabel id="demo-simple-select-error-label">
                            Device
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-error-label"
                            id="device"
                            value={pair.device.deviceName}
                            label="Device"
                            name="device"
                            // onChange={handlePairsChange}
                          >
                            <MenuItem value={pair?.device?.deviceName}>
                              {pair?.device?.deviceName}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            >
                              <InputLabel id="demo-simple-select-error-label">
                                Camera 1
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-error-label"
                                id="Camera 1"
                                value={pair?.camera1?.channelReference}
                                label="Camera 1"
                                name="Camera 1"
                              >
                                <MenuItem
                                  value={pair?.camera1?.channelReference}
                                >
                                  {pair?.camera1?.channelReference}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            >
                              <InputLabel id="demo-simple-select-error-label">
                                Camera 2
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-error-label"
                                id="Camera 2"
                                value={pair?.camera2?.channelReference}
                                label="Camera 2"
                                name="Camera 2"
                              >
                                <MenuItem
                                  value={pair?.camera2?.channelReference}
                                >
                                  {pair?.camera2?.channelReference}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                )}
              {oneUserDetail &&
                oneUserDetail !== null &&
                oneUserDetail?.broadcasts?.length > 0 && (
                  <div className="user-sub-heading">
                    <h2>Broadcast</h2>
                    {oneUserDetail?.broadcasts.map(
                      (broad: { _id: string; title: string; pairs: any[] }) => (
                        <Grid
                          style={{ marginBottom: "20px" }}
                          key={broad._id}
                          container
                          spacing={2}
                        >
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="broadcast"
                              name="broadcast"
                              label="Broadcast Name"
                              placeholder="Enter broadcast name"
                              type={"text"}
                              defaultValue={broad.title}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-error-label">
                                Device
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-error-label"
                                id="pairs"
                                value={broadcastName}
                                multiple
                                label="Device"
                                name="pairs"
                                onChange={handleBroadcastChange}
                                input={<OutlinedInput label="Device" />}
                                MenuProps={MenuProps}
                              >
                                {broad.pairs.map((o: any) => (
                                  <MenuItem
                                    key={o._id}
                                    style={getStyles(
                                      `pairs-${o._id}`,
                                      broadcastName,
                                      theme
                                    )}
                                    value={o.device?.deviceName}
                                  >
                                    {o.device?.deviceName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </div>
                )}
              <div className="user-btns">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "270px" }}
                  type="button"
                  size="large"
                  onClick={() => nav(ROUTE_URLS.USERS)}
                  className="loginButton"
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{
                    borderColor: `${
                      theme.palette.mode === "dark"
                        ? "primary.light"
                        : "primary.main"
                    }`,
                    color: `${
                      theme.palette.mode === "dark"
                        ? "primary.light"
                        : "primary.main"
                    }`,
                    "&:hover": {
                      borderColor: `${
                        theme.palette.mode === "dark"
                          ? "primary.dark"
                          : "primary.main"
                      }`,
                      color: `${
                        theme.palette.mode === "dark"
                          ? "primary.dark"
                          : "primary.main"
                      }`,
                    },
                  }}
                  type="button"
                  size="large"
                  onClick={() => nav(ROUTE_URLS.USERS)}
                  style={{ width: "270px" }}
                  className="loginButton"
                >
                  Cancel
                </Button>
              </div>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
};

export default UserDetail;
