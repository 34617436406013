import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  GENERIC_ERROR_MESSAGE,
  SERVER_AUTH_ERROR_STATUS_CODE,
  SERVER_VALIDATION_STATUS_CODE,
} from "../config/constants";
import ROUTE_URLS from "../config/routes";
import LocalstorageService from "./localstorage-services";

// Apply base url for axios
const API_URL: string = `${process.env.REACT_APP_BASE_URL}/api` || "";
const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config: any) => {
    const token = LocalstorageService.getLoggedInUserToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const { data, status }: any = error.response;
    if (status === SERVER_AUTH_ERROR_STATUS_CODE) {
      LocalstorageService.logoutUser();
      window.location.replace(ROUTE_URLS.LOGIN);
    }
    if (status === SERVER_VALIDATION_STATUS_CODE) {
      const { error } = data as { error: Record<string, string[]> };
      const errorsArray = [];
      for (const key in error) {
        if (Object.hasOwnProperty.call(error, key)) {
          const _error = error[key];
          errorsArray.push(_error);
        }
      }
      return Promise.reject(errorsArray);
    }
    return Promise.reject(GENERIC_ERROR_MESSAGE);
  }
);

export async function get(url: string, config: AxiosRequestConfig = {}) {
  return await axiosApi.get(url, { ...config });
}

export async function post(
  url: string,
  data: any,
  config: AxiosRequestConfig = {}
) {
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function put(
  url: string,
  data: any,
  config: AxiosRequestConfig = {}
) {
  return await axiosApi.put(url, { ...data }, { ...config });
}

export async function del(url: string, config: AxiosRequestConfig = {}) {
  return await axiosApi.delete(url, { ...config });
}

export async function delPayload(
  url: string,
  data: any,
  config: AxiosRequestConfig = {}
) {
  return await axiosApi.delete(url, {
    // Authorization: { ...config },
    data: data,
  });
}

export async function postFormData(
  url: string,
  data: FormData,
  config: AxiosRequestConfig = {}
) {
  return axiosApi.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...config.headers,
    },
    ...config,
  });
}
