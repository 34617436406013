/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import editPencil from "../../../assets/images/editPencil.svg";
import deleteBox from "../../../assets/images/trash.svg";
import DeleteModal from "../../../common/DeleteModal";
import { AppDispatch, RootState } from "../../../config/store";
import LocalstorageService from "../../../helper/localstorage-services";
import ToastService from "../../../helper/toast-services";
import {
  addChannelDetail,
  getAllChannel,
  updateChannelDetail,
} from "../middleware/channel";
import { resetChannelMessage } from "../slices/channelSlice";
import { channelValidation } from "../validation/channelValidation";

import { useNavigate } from "react-router-dom";

interface Data {
  channel: string;
  camera_feed: string;
  channel_type: string;
  group: string;
  status: string;
  action: HTMLElement;
}

interface ChannelNameInterface {
  type: string;
  streamKey: string;
  channelName: string;
  ingestEndpoint: string;
  playbackUrl: string;
  bitrate: number;
  whiteBalance: number;
  videoFrameRate: number;
}

interface ChannelSchema {
  streamKey: string;
  ingestEndpoint: string;
  playbackUrl: string;
  channelName: string;
  channelReference: string;
  deviceName: string;
  camera: string;
  _id: string;
  userId: string;
  createdAt: string;
  status: string;
  type: string;
  bitrate: number;
  whiteBalance: number;
  videoFrameRate: number;
  isReserved: boolean;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "channel",
    numeric: false,
    disablePadding: false,
    label: "Channel",
  },
  {
    id: "channel_type",
    numeric: false,
    disablePadding: false,
    label: "Channel Type",
  },
  // {
  //   id: "camera_feed",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "camera feed",
  // },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const Channel = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    channelDetailLoading,
    channelDetail,

    addChannelLoading,
    addChannelMessage,
    addChannelError,

    deleteChannelLoading,
    deleteChannelMessage,
    deleteChannelError,

    updateChannelLoading,
    updateChannelError,
    updateChannelMessage,
  } = useSelector((state: RootState) => state.channel);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] =
    React.useState<boolean>(false);
  const [isAddChannel, setIsAddChannel] = useState<boolean>(false);
  const [oneChannelData, setOneChannelData] = useState<ChannelSchema | null>(
    null
  );
  const [whiteBalance, setWhiteBalance] = useState(6500);
  const [bitrate, setBitRate] = useState(2000);
  const [framerate, setFramerate] = useState(60);

  const userData = LocalstorageService.getLoggedInUserDetails();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllChannel(userData._id));
  }, [addChannelMessage, deleteChannelMessage, updateChannelMessage]);

  useEffect(() => {
    if (addChannelMessage || updateChannelMessage) {
      ToastService.success(addChannelMessage || updateChannelMessage);
      handleCloseModal();
    }
    if (!deleteChannelLoading && deleteChannelMessage) {
      ToastService.success(deleteChannelMessage);
      handleCloseDeleteModal();
    }
  }, [addChannelMessage, updateChannelMessage, deleteChannelMessage]);

  useEffect(() => {
    if (
      !channelDetailLoading &&
      !addChannelLoading &&
      initialLoading &&
      channelDetail
    ) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [channelDetail]);

  const handleClickOnMore = (channel: ChannelSchema) => {
    formik.setValues({
      channelName: channel.channelName || "",
      type: channel.type || "YOUTUBE",
      streamKey: channel.streamKey || "",
      ingestEndpoint: channel.ingestEndpoint,
      playbackUrl: channel.playbackUrl || "",
      bitrate: channel.bitrate || 2000,
      whiteBalance: channel.whiteBalance || 6500,
      videoFrameRate: channel.videoFrameRate || 60,
    });
    setBitRate(channel.bitrate || 2000);
    setWhiteBalance(channel.whiteBalance || 6500);
    setFramerate(channel.videoFrameRate || 60);
    setOneChannelData(channel);
  };

  const initialValues: ChannelNameInterface = {
    type: "YOUTUBE",
    streamKey: "",
    channelName: "",
    ingestEndpoint: "",
    playbackUrl: "",
    bitrate: 2000,
    whiteBalance: 6500,
    videoFrameRate: 60,
  };

  const handleSubmit = (values: ChannelNameInterface) => {
    // if (oneChannelData !== null) {
    //   dispatch(updateChannelDetail(oneChannelData?._id, values));
    // } else {
    //   dispatch(addChannelDetail(values));
    // }
  };

  // const handleChannelSubmit = (event: any) => {
  //   event.preventDefault();
  //   const form = event.target;
  //   const channelReference = form.channelReference?.value;
  //   const type = form.type?.value;
  //   const streamKey = form.streamKey?.value;
  //   // const ingestEndpoint = form.ingestEndpoint?.value;
  //   const playbackUrl = form.playbackUrl?.value;
  //   const createChannelValue: any = {
  //     type,
  //     channelReference,
  //     streamKey,
  //     ingestEndpoint: "rtmp://a.rtmp.youtube.com/live2/",
  //     playbackUrl,
  //   };
  //   if (oneChannelData !== null) {
  //     dispatch(updateChannelDetail(oneChannelData?._id, createChannelValue));
  //   } else {
  //     dispatch(
  //       addChannelDetail({
  //         name: channelReference,
  //         type: "YOUTUBE",
  //         streamKey,
  //         ingestEndpoint: "rtmp://a.rtmp.youtube.com/live2/",
  //         playbackUrl,
  //       })
  //     );
  //   }
  // };

  const handleCloseAddModal = () => {
    setIsAddChannel(false);
    setOneChannelData(null);
    formik.resetForm();
  };

  const handleCloseDeleteModal = () => {
    setSelected([]);
    setOpenDeleteDialog(false);
    dispatch(resetChannelMessage());
  };

  const handleDelete = () => {
    if (selected.length < 1) {
    } else if (selected.length < 2) {
      dispatch(updateChannelDetail(selected[0], { deletedAt: new Date() }));
    } else {
      selected.map((channelId: string) => {
        dispatch(updateChannelDetail(channelId, { deletedAt: new Date() }));
      });
    }
    handleCloseDeleteModal();
  };

  const handleChange = (id: string, method: string) => {
    if (selected.includes(id)) {
      const data = [...selected];
      const dataIndex = data.findIndex((o) => {
        return o === id;
      });
      data.splice(dataIndex, 1);
      setSelected(data);
    } else {
      setSelected([...selected, id]);
    }
    if (method === "single") {
      setOpenDeleteDialog(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: channelValidation,
  });

  const handleBitChange = (event: any, newValue: any) => {
    setBitRate(event.target.value);
  };

  const handleWhiteRateChange = (event: any, newValue: any) => {
    setWhiteBalance(newValue);
    // You can also handle the volume change logic here
  };

  const handleFramerateChange = (event: SelectChangeEvent<number>) => {
    setFramerate(event.target.value as number);
  };

  const handleChannelSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const channelName = form.channelName?.value;
    const type = form.type?.value;
    const streamKey = form.streamKey?.value;
    const playbackUrl = form.playbackUrl?.value;

    const createChannelValue: any = {
      type: "YOUTUBE",
      channelName: channelName,
      channelReference: channelName,
      streamKey,
      ingestEndpoint: "rtmp://a.rtmp.youtube.com/live2/",
      playbackUrl,
      videoFrameRate: framerate, // Use component state
      whiteBalance, // Use component state
      bitrate, // Use component state
    };

    if (oneChannelData !== null) {
      dispatch(updateChannelDetail(oneChannelData?._id, createChannelValue));
    } else {
      dispatch(addChannelDetail(createChannelValue));
    }
  };

  const handleCloseModal = () => {
    setIsAddChannel(false);
    resetFormValues();
    dispatch(resetChannelMessage());
  };

  const resetFormValues = () => {
    formik.resetForm();
    setBitRate(2000);
    setWhiteBalance(6500);
    setFramerate(60);
  };

  return (
    <>
      <div className="mainPageHeading">
        <h1
          className={`${
            theme.palette.mode === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Channels
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            style={{ width: "260px" }}
            onClick={() => {
              formik.setValues(initialValues);
              setIsAddChannel(true);
            }}
            className="addChannel"
          >
            <AddIcon />
            Add Channel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            size="large"
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
            className="loginButton"
          >
            Delete
          </Button>
        </div>
      </div>

      {channelDetailLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        <span
                          className={`${
                            theme.palette.mode === "light"
                              ? "login-text-dark"
                              : ""
                          } tableHead`}
                        >
                          {headCell.label}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {channelDetail &&
                    channelDetail.length > 0 &&
                    channelDetail.map((row: any, index: any) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              onChange={() => handleChange(row._id, "multiple")}
                              checked={
                                selected.includes(row._id) ? true : false
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {" "}
                              {row.channelName ? row.channelName : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {row.type ? row.type : "- - - - -"}
                            </p>
                          </TableCell>

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.status === "active" ? (
                              <p style={{ color: "#28C76F" }}>Active</p>
                            ) : (
                              <p style={{ color: "#EA5455" }}>Inactive</p>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <div
                                  className="tableLink"
                                  onClick={() => {
                                    handleClickOnMore(row);
                                    setIsAddChannel(true);
                                  }}
                                >
                                  <span>
                                    <img src={editPencil} alt="edit" />
                                  </span>
                                  {/* <img src={chevronRight} alt="details" /> */}
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <img
                                  onClick={() =>
                                    handleChange(row._id, "single")
                                  }
                                  src={deleteBox}
                                  alt="delete"
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {channelDetail && channelDetail.length === 0 && (
              <span
                className={`${
                  theme.palette.mode === "light" ? "login-text-dark" : ""
                } no-data-found tableRows`}
              >
                No Channel Found
              </span>
            )}
          </Paper>
        </Box>
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={isAddChannel}
        onClose={handleCloseAddModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Channel</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseAddModal}
            />
          </div>
        </DialogTitle>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <form onSubmit={handleChannelSubmit}>
          <DialogContent>
            {(addChannelError || updateChannelError) && (
              <Alert severity="error">
                {addChannelError || updateChannelError}
              </Alert>
            )}
            <TextField
              fullWidth
              variant="outlined"
              id="channelName"
              name="channelName"
              label="Channel Name"
              placeholder="Enter channel name"
              type={"text"}
              required
              value={formik.values.channelName}
              onChange={formik.handleChange}
              error={
                formik.touched.channelName && Boolean(formik.errors.channelName)
              }
              helperText={
                formik.touched.channelName && formik.errors.channelName
              }
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />

            <>
              <TextField
                fullWidth
                variant="outlined"
                id="streamKey"
                name="streamKey"
                label="StreamKey"
                placeholder="Enter streamKey"
                type={"text"}
                value={formik.values.streamKey}
                onChange={formik.handleChange}
                error={
                  formik.touched.streamKey && Boolean(formik.errors.streamKey)
                }
                helperText={formik.touched.streamKey && formik.errors.streamKey}
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <Box sx={{ marginTop: "20px", px: "10px" }}>
                <Typography id="bitrate-slider" gutterBottom>
                  Bitrate: {bitrate || formik.values.bitrate}
                </Typography>
                <Slider
                  value={bitrate || formik.values.bitrate}
                  onChange={handleBitChange}
                  aria-labelledby="bitrate-slider"
                  step={100}
                  min={1500}
                  max={4500}
                  valueLabelDisplay="auto"
                  sx={{
                    color: "primary.main",
                    "& .MuiSlider-thumb": {
                      width: 24,
                      height: 24,
                    },
                    "& .MuiSlider-markLabel": {
                      fontSize: "0.8rem",
                    },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "20px", px: "10px" }}>
                <Typography id="bitrate-slider" gutterBottom>
                  White Balance: {whiteBalance}
                </Typography>
                <Slider
                  value={whiteBalance}
                  onChange={handleWhiteRateChange}
                  aria-labelledby="bitrate-slider"
                  step={100}
                  min={1000}
                  max={8000}
                  valueLabelDisplay="auto"
                  sx={{
                    color: "primary.main",
                    "& .MuiSlider-thumb": {
                      width: 24,
                      height: 24,
                    },
                    "& .MuiSlider-markLabel": {
                      fontSize: "0.8rem",
                    },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "20px", px: "10px" }}>
                <Typography id="framerate-select" gutterBottom>
                  Video Framerate
                </Typography>
                <Select
                  value={framerate}
                  onChange={handleFramerateChange}
                  displayEmpty
                  fullWidth
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.09)"
                        : "rgba(0, 0, 0, 0.06)",
                    "& .MuiSelect-select": {
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    },
                  }}
                >
                  <MenuItem value={30}>30 fps</MenuItem>
                  <MenuItem value={50}>50 fps</MenuItem>
                  <MenuItem value={60}>60 fps</MenuItem>
                </Select>
              </Box>
            </>
          </DialogContent>
          <DialogActions
            style={{ marginBottom: "20px", justifyContent: "center" }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="button"
              size="large"
              style={{ width: "270px" }}
              className="loginButton"
              disabled={addChannelLoading || updateChannelLoading}
              onClick={handleCloseAddModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "270px" }}
              type="submit"
              size="large"
              disabled={addChannelLoading || updateChannelLoading}
              className="loginButton"
            >
              {addChannelLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <DeleteModal
        handleClose={handleCloseDeleteModal}
        handleOnDelete={handleDelete}
        isOpen={openDeleteDialog}
        loading={deleteChannelLoading}
        deleteErrorMessage={deleteChannelError}
        title="Once it is deleted you will not be able to recover the data again!"
        buttonName="Delete"
      />
    </>
  );
};

export default Channel;
