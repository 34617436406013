import React from "react";
import {
  InputAdornment,
  IconButton,
  Button,
  CssBaseline,
  TextField,
  Box,
  Alert,
  Stack,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import { resetPasswordValidation } from "../validation/authValidationSchema";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../middleware/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../config/store";
import ROUTE_URLS from "../../../config/routes";
import footer from "../../../assets/images/loginFooter.svg";
import ToastService from "../../../helper/toast-services";
import { resetResetPassword } from "../slices/authSlice";
import { PasswordResetInterface } from "../../../common/interfaces/interface";

const ResetPassword = () => {
  const theme = useTheme();

  const dispatch: AppDispatch = useDispatch();
  const nav = useNavigate();
  const { resetPasswordLoading, resetPasswordError, resetPasswordDetails } =
    useSelector((state: RootState) => state.auth);
  const { reset_password_token } = useParams();

  const initialValues: PasswordResetInterface = {
    new_password: "",
    confirm_password: "",
  };

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const handlePasswordShow = () => {
    setShowPassword({
      password: !showPassword.password,
      confirmPassword: showPassword.confirmPassword,
    });
  };
  const handleConfirmPasswordShow = () => {
    setShowPassword({
      password: showPassword.password,
      confirmPassword: !showPassword.confirmPassword,
    });
  };

  useEffect(() => {
    if (resetPasswordDetails && !resetPasswordLoading) {
      formik.resetForm();
      ToastService.success(resetPasswordDetails);
      dispatch(resetResetPassword());
      nav(ROUTE_URLS.LOGIN);
    }
  }, [resetPasswordDetails]);

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleSubmit = (values: PasswordResetInterface) => {
    // API dispatch calling here... //
    const payload = {
      reset_password_token: reset_password_token,
      new_password: values.new_password,
    };
    dispatch(resetPassword(payload));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: resetPasswordValidation,
  });

  return (
    <div className="center-auth-component" style={{ height: "100vh" }}>
      <CssBaseline />
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 350,
          }}
        >
          <h5 className="login-text" style={{ fontSize: "36px" }}>
            Reset Password
          </h5>

          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            {resetPasswordError && (
              <Alert severity="error">{resetPasswordError}</Alert>
            )}
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                id="standard-password-input"
                name="new_password"
                label="New Password"
                placeholder="New Password"
                type={showPassword.password ? "text" : "password"}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handlePasswordShow}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="standard-password-input"
                name="confirm_password"
                label="Confirm Password"
                placeholder="Confirm Password"
                type={showPassword.confirmPassword ? "text" : "password"}
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirm_password &&
                  Boolean(formik.errors.confirm_password)
                }
                helperText={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleConfirmPasswordShow}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                size="large"
                disabled={resetPasswordLoading}
                className="loginButton"
                style={{ marginBottom: "20px" }}
              >
                {resetPasswordLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Reset Password"
                )}
              </Button>
              <div
                style={{
                  marginBottom: "40px",
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`${
                  theme.palette.mode === "dark"
                    ? "register-link-light"
                    : "register-link-dark"
                }`}
              >
                <Link
                  to={ROUTE_URLS.LOGIN}
                  style={{ display: "flex", justifyItems: "center" }}
                >
                  <KeyboardBackspaceIcon /> &nbsp; <span>Return to Login </span>
                </Link>
              </div>
            </div>
          </form>
        </Box>
      </div>
      <img className="footer-wave" src={footer} alt="footer" />
    </div>
  );
};
export default ResetPassword;
