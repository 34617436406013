import { AppDispatch } from "../../../config/store";
import {
	addBroadcastError,
	addBroadcastRequest,
	addBroadcastSuccess,
	broadcastError,
	broadcastLiveStreamError,
	broadcastLiveStreamRequest,
	broadcastLiveStreamSuccess,
	broadcastRecordings,
	broadcastRequest,
	broadcastSuccess,
	deleteBroadcastError,
	deleteBroadcastRequest,
	deleteBroadcastSuccess,
	endLiveStreamError,
	endLiveStreamRequest,
	endLiveStreamSuccess,
	getOneBroadcastError,
	getOneBroadcastRequest,
	getOneBroadcastSuccess,
	updateBroadcastError,
	updateBroadcastRequest,
	updateBroadcastSuccess,
} from "../../BroadcastManagement/slices/broadcastSlice";
import { BroadcastService } from "../services/broadcastServices";

export function addBroadcast(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(addBroadcastRequest());
		BroadcastService.addBroadcastData(payload)
			.then((response) => {
				const { success, error, message } = response.data;
				if (success) {
					dispatch(addBroadcastSuccess(message));
				} else {
					dispatch(addBroadcastError(error));
				}
			})
			.catch((error) => {
				dispatch(addBroadcastError(error));
			});
	};
}

export function broadcastDetailList(user_id: string) {
	return (dispatch: AppDispatch) => {
		dispatch(broadcastRequest());
		BroadcastService.broadcastList(user_id)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(broadcastSuccess(data));
				} else {
					dispatch(broadcastError(error));
				}
			})
			.catch((error) => {
				dispatch(broadcastError(error));
			});
	};
}

export function getBroadcastRecordings(querry: string) {
	return (dispatch: AppDispatch) => {
		BroadcastService.broadcastRecordings(querry)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(broadcastRecordings(data))
				} else {
					console.log(error);
					dispatch(broadcastRecordings([]));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
}

export function deleteBroadcastDetail(payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(deleteBroadcastRequest());
		BroadcastService.deleteBroadcast(payload)
			.then((response) => {
				const { success, error, message } = response.data;
				if (success) {
					dispatch(deleteBroadcastSuccess(message));
				} else {
					dispatch(deleteBroadcastError(error));
				}
			})
			.catch((error) => {
				dispatch(deleteBroadcastError(error));
			});
	};
}

export function getOneBroadcastDetail(broadcast_id: string) {
	return (dispatch: AppDispatch) => {
		dispatch(getOneBroadcastRequest());
		BroadcastService.getOneBroadcast(broadcast_id)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(getOneBroadcastSuccess(data));
				} else {
					dispatch(getOneBroadcastError(error));
				}
			})
			.catch((error) => {
				dispatch(getOneBroadcastError(error));
			});
	};
}

export function updateBroadcastDetail(broadcast_id: string, payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(updateBroadcastRequest());
		BroadcastService.updateOneBroadcast(broadcast_id, payload)
			.then((response) => {
				const { success, error, message } = response.data;
				if (success) {
					dispatch(updateBroadcastSuccess(message));
				} else {
					dispatch(updateBroadcastError(error));
				}
			})
			.catch((error) => {
				dispatch(updateBroadcastError(error));
			});
	};
}

export function watchLiveStream(broadcast_id: string) {
	return (dispatch: AppDispatch) => {
		dispatch(broadcastLiveStreamRequest());
		BroadcastService.liveBroadcast(broadcast_id)
			.then((response) => {
				const { success, error, data } = response.data;
				if (success) {
					dispatch(broadcastLiveStreamSuccess(data));
				} else {
					dispatch(broadcastLiveStreamError(error));
				}
			})
			.catch((error) => {
				dispatch(broadcastLiveStreamError(error));
			});
	};
}

export function endLiveStream(broadcast_id: string, payload: object) {
	return (dispatch: AppDispatch) => {
		dispatch(endLiveStreamRequest());
		BroadcastService.EndLiveBroadcast(broadcast_id, payload)
			.then((response) => {
				const { success, error, message } = response.data;
				if (success) {
					dispatch(endLiveStreamSuccess(message));
				} else {
					dispatch(endLiveStreamError(error));
				}
			})
			.catch((error) => {
				dispatch(endLiveStreamError(error));
			});
	};
}
