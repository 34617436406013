/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_URLS from "../../../config/routes";
import HistoryPlayer from "../../CustomHistoryPlayer/HistoryPlayer";
import LocalstorageService from "../../../helper/localstorage-services";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../config/store";
import { getAllChannel } from "../middleware/channel";
import { useEffect, useState } from "react";
import { getBroadcastRecordings } from "../../BroadcastManagement/middleware/broadcast";
import {
  dateWithMonthFormat,
  timeOnlyFormat,
} from "../../../helper/dateFormate";
import {
  recordingSchema,
  setVideoOnDemandUrl,
} from "../../BroadcastManagement/slices/broadcastSlice";

const ChannelHistory = () => {
  const { channel_id, vod_src } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { broadRecordings } = useSelector(
    (state: RootState) => state.broadcast
  );

  const [date, setDate] = useState<String>("");

  useEffect(() => {
    dispatch(getBroadcastRecordings(`channelId=${channel_id}`));
  }, []);

  useEffect(() => {
    dispatch(setVideoOnDemandUrl(broadRecordings[0]?.vodURL));
    navigate(
      `${ROUTE_URLS.CHANNEL_HISTORY}/${channel_id}/${broadRecordings[0]?._id}`
    );
  }, [broadRecordings[0]?.vodURL]);

  const filteredData = broadRecordings?.filter((item) => {
    const filteredDate = date.toLowerCase();
    return item?.createdAt?.toLowerCase().includes(filteredDate);
  });

  const data: recordingSchema[] = filteredData;
  const groupedData: { [key: string]: recordingSchema[] } = {};

  data.forEach((item) => {
    const broadcastReference = item.broadcastId.title;
    if (!groupedData[broadcastReference]) {
      groupedData[broadcastReference] = [];
    }
    groupedData[broadcastReference].push(item);
  });

  const setVodUrl = (url: string, recordId: string) => {
    dispatch(setVideoOnDemandUrl(url));
    navigate(`${ROUTE_URLS.CHANNEL_HISTORY}/${channel_id}/${recordId}`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "20%",
          margin: "8px 0px",
        }}
      >
        <input
          type="date"
          name="selectedDate"
          id="selectedDate"
          min="2023-01-01"
          onChange={(date) => {
            setDate(date.target.value);
          }}
          style={{
            width: "70%",
            height: "30px",
            padding: "5px",
            fontSize: "20px",
            border: "1px solid #6d3190",
            borderRadius: "5px 0px 0px 5px",
          }}
        />
        <button
          style={{
            width: "28%",
            height: "30px",
            fontSize: "20px",
            border: "1px solid #6d3190",
            borderRadius: "0px 5px 5px 0px",
          }}
          onClick={() => {
            setDate("");
          }}
        >
          Clear
        </button>
      </div>
      {filteredData.length ? (
        <div style={{ display: "flex" }}>
          <div style={{ width: "20%" }} className="historyMenu">
            <div
              style={{
                height: "80vh",
                overflowY: "scroll",
                paddingRight: "1px",
              }}
            >
              <div>
                {Object.entries(groupedData).map(([channelRef, items]) => (
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "scroll",
                      marginBottom: "5px",
                    }}
                    key={channelRef}
                  >
                    <h4
                      style={{
                        position: "sticky",
                        marginTop: "0",
                        top: "6px",
                        backgroundColor: "white",
                        padding: "5px",
                      }}
                    >
                      {channelRef}
                    </h4>
                    {items.map((item) => (
                      <p
                        {...(vod_src === item?._id
                          ? {
                              style: {
                                background: "#6d3190",
                                color: "white",
                                paddingLeft: "15px",
                              },
                            }
                          : {})}
                        onClick={() => {
                          setVodUrl(item?.vodURL, item?._id);
                        }}
                      >
                        {dateWithMonthFormat(item?.createdAt)}-{" "}
                        {timeOnlyFormat(item?.createdAt)}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ width: "80%" }}>
            <HistoryPlayer />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "red", marginTop: "10px" }}>No recordings Found</p>
        </div>
      )}
    </div>
  );
};

export default ChannelHistory;
