import { get, post, postFormData, put } from "../../../helper";

const URI = "/user";

const getUserDetails = () => {
	return get(`${URI}/list`);
};

const updatePassword = (payload: object) => {
	return post(`${URI}/change-password`, payload);
};

const updateProfile = (payload: object) => {
	return put(`${URI}/update-profile`, payload);
};

const updateProfileImage = (payload: FormData) => {
	return postFormData(`${URI}/image/upload`, payload);
};

const logoutUser = () => {
	return get("auth/logout");
};

const ProfileService = {
	getUserDetails,
	logoutUser,
	updatePassword,
	updateProfile,
	updateProfileImage,
};
export default ProfileService;
