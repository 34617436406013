import { delPayload, get, post, put } from "../../../helper";

const URI = "broadcast";

const addBroadcastData = (payload: object) => {
	return post(`${URI}/add`, payload);
};

const broadcastList = (user_id: string) => {
	return get(`${URI}/?createdBy=${user_id}`);
};

const broadcastRecordings = (querry: string) => {
	return get(`${URI}/recordings?${querry}`);
};

const getOneBroadcast = (broadcast_id: string) => {
	return get(`${URI}/${broadcast_id}`);
};

const updateOneBroadcast = (broadcast_id: string, payload: object) => {
	return put(`${URI}/update/${broadcast_id}`, payload);
};

const deleteBroadcast = (payload: object) => {
	return delPayload(`${URI}/delete`, payload);
};

const liveBroadcast = (broadcast_id: string) => {
	return get(`${URI}/list/${broadcast_id}`);
};

const EndLiveBroadcast = (broadcast_id: string, payload: object) => {
	return put(`${URI}/end-time/${broadcast_id}`, payload);
};

export const BroadcastService = {
	addBroadcastData,
	broadcastList,
	broadcastRecordings,
	deleteBroadcast,
	getOneBroadcast,
	updateOneBroadcast,
	liveBroadcast,
	EndLiveBroadcast,
};
