/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import {
  ChannelCameraSchema,
  broadcastRecordings,
  resetEndStreamMessage,
  setdistributionDomainName,
} from "../slices/broadcastSlice";
import ROUTE_URLS from "../../../config/routes";
import { ChannelSchema } from "../../Channel/slices/channelSlice";
import ToastService from "../../../helper/toast-services";
import {
  broadcastDetailList,
  endLiveStream,
  getBroadcastRecordings,
  watchLiveStream,
} from "../middleware/broadcast";
import DeleteModal from "../../../common/DeleteModal";
import HistoryIcon from "@mui/icons-material/History";
import LocalstorageService from "../../../helper/localstorage-services";
import ReactPlayer from "react-player";
import { put } from "../../../helper";
import notLive from "../../../assets/images/no_live_streaming.jpg";

const BroadcastStreaming = () => {
  const theme = useTheme();
  const { broadcast_id, channel_id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {
    broadcastDetail,
    broadRecordings,
    broadcastLiveStreamLoading,
    broadcastLiveStream,
    endStreamLoading,
    endStreamMessage,
    endStreamErrorMessage,
  } = useSelector((state: RootState) => state.broadcast);
  const { updateChannelRange, updateChannelRangeLoading } = useSelector(
    (state: RootState) => state.channel
  );
  const nav = useNavigate();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [liveStream, setLiveStream] = useState<
    ChannelCameraSchema | ChannelSchema | null
  >(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isConfirmEnd, setIsConfirmEnd] = useState<boolean>(false);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const userData = LocalstorageService.getLoggedInUserDetails();

  useEffect(() => {
    dispatch(broadcastDetailList(userData._id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getBroadcastRecordings(`broadcastId=${broadcast_id}`));
  }, []);

  useEffect(() => {
    broadcastLiveStream?.channels[0]?.distributionDomainName &&
      dispatch(
        setdistributionDomainName(
          broadcastLiveStream?.channels[0]?.distributionDomainName +
            "/recording-started-latest.json"
        )
      );
    broadcastLiveStream?.channels[0]?.distributionDomainName &&
      saveRecordings(broadcastLiveStream?.channels[0]?.distributionDomainName!);
  }, [broadcastLiveStream?.channels[0]?.distributionDomainName]);

  useEffect(() => {
    if (
      !broadcastLiveStreamLoading &&
      !updateChannelRangeLoading &&
      initialLoading &&
      broadcastLiveStream
    ) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [broadcastLiveStream]);
  const handleEndLiveStream = () => {
    if (broadcast_id) {
      dispatch(endLiveStream(broadcast_id, { entTime: new Date() }));
    }
  };

  useEffect(() => {
    if (broadcast_id) {
      dispatch(watchLiveStream(broadcast_id));
    }
  }, [broadcast_id, updateChannelRange]);

  useEffect(() => {
    if (liveStream !== null && !isError && updateChannelRange !== null) {
      setLiveStream(updateChannelRange);
    }
  }, [updateChannelRange]);

  useEffect(() => {
    if (!endStreamLoading && endStreamMessage) {
      setIsConfirmEnd(false);
      ToastService.success(endStreamMessage);
      dispatch(resetEndStreamMessage());
      nav(ROUTE_URLS.BROADCAST_MANAGEMENT);
    }
  }, [endStreamMessage]);

  const handleCloseConfirm = () => {
    setIsConfirmEnd(false);
  };

  // update Recordings function
  const getUpdateBrodcastData = (payload: any) => {
    return put(
      `https://bond.niftyitsolution.com/api/broadcast/updaterecording`,
      payload
    );
  };

  function updateBroadCast(update: any) {
    getUpdateBrodcastData(update)
      .then((response: any) => {
        const { success, error } = response.data;
        if (success) {
          // console.log(message);
        } else {
          console.log(error);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  const saveRecordings = async (domainName: string) => {
    const response = await fetch(
      `https://${domainName}/recording-started-latest.json`
    );
    const data = await response.json();

    if (data.isChannelLive) {
      const mstkey = data?.masterKey;
      if (mstkey) {
        const recordingsData = {
          vodURL: "https://" + domainName + "/" + mstkey,
          channelId: channel_id,
          broadcastId: broadcast_id,
          playlistDuration: data?.playlistDuration,
          recordingStartedAt: data?.recordingStartedAt,
        };
        updateBroadCast(recordingsData);
      }
    } else {
      setIsError(true);
      ToastService.error(
        "This channel is not live. Please start the live stream first."
      );
    }
  };

  const toggleSize = (index: number, domainName: string) => {
    saveRecordings(domainName);
    if (index === expandedIndex) {
      return;
    }
    setExpandedIndex(index === expandedIndex ? -1 : index);
    setIsError(false);
  };

  const handlePlayerClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <>
      {broadcastLiveStreamLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <div className="streamingPage">
          <div className="left">
            <div className="live-streaming">
              <div className="video-grid">
                {broadcastLiveStream &&
                  broadcastLiveStream.channels &&
                  broadcastLiveStream.channels.length > 0 &&
                  broadcastLiveStream.channels.map((url, index) => (
                    <div
                      key={index}
                      className={`video-item ${
                        expandedIndex === index ? "expanded" : ""
                      }`}
                      onClick={() =>
                        toggleSize(index, url.distributionDomainName)
                      }
                    >
                      <ReactPlayer
                        url={url.playbackUrl}
                        width="100%"
                        height="100%"
                        playing={true}
                        onClick={(e: { preventDefault: () => void }) =>
                          handlePlayerClick(e)
                        }
                        light={
                          expandedIndex === index && isError ? (
                            <img src={notLive} width={"90%"} alt="not-found" />
                          ) : (
                            false
                          )
                        }
                        onPlay={() => {
                          setIsError(false);
                        }}
                        controls={expandedIndex === index ? true : false}
                        muted
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="right">
            {broadRecordings.length > 0 ? (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
                onClick={() => {
                  nav(`${ROUTE_URLS.BROADCAST_HISTORY}/${broadcast_id}/0`);
                }}
                className="loginButton"
              >
                See Previous recordings
                <HistoryIcon />
              </Button>
            ) : (
              " "
            )}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              // onClick={() => setOpenAddDialog(true)}
              className="loginButton"
            >
              Go To Youtube
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="submit"
              size="large"
              disabled={endStreamLoading}
              onClick={() => setIsConfirmEnd(true)}
              className="loginButton"
            >
              End Stream
            </Button>
          </div>
        </div>
      )}

      <DeleteModal
        handleClose={handleCloseConfirm}
        handleOnDelete={handleEndLiveStream}
        isOpen={isConfirmEnd}
        loading={endStreamLoading}
        deleteErrorMessage={endStreamErrorMessage}
        title="You will be able to watch the recorded stream after the stream ends."
        buttonName="End"
      />
    </>
    // <div>
    //   <App></App>
    // </div>
  );
};

export default BroadcastStreaming;
